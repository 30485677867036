import React, { useEffect } from "react";
import {
    DashboardFilterConfig,
    DashboardPage,
    DashboardWidgetConfig,
    DataSource,
    DateParams,
    DryChartWidgetConfig,
    DryFilterSuggestionConfig,
    DryTableWidgetConfig,
    FilterOp,
    FilterValue,
    ParamFilter
} from "../types";
import { Button, ExpandMoreIcon, IconButton, Paper, Tooltip, Typography } from "@firecms/ui";
import { FilterView } from "./FilterView";
import { ErrorBoundary, useSnackbarController } from "@firecms/core";
import { SQLPreview } from "./SQLPreview";
import { useDataki } from "../DatakiProvider";
import { formatSQL, getDialectFromDataSources } from "../utils/sql";
import { useDashboardStateContext } from "./dashboards/DashboardPageView";

export function FilterSuggestionView({
                                         suggestion,
                                         dashboardId,
                                         dashboardPageId,
                                         dashboardPage,
                                         paramFilters,
                                         onSuggestionUpdate,
                                         params,
                                         maxWidth,
                                         dataSources
                                     }: {
    suggestion: DryFilterSuggestionConfig,
    onSuggestionUpdate: (suggestion: DryFilterSuggestionConfig) => void,
    dashboardId: string,
    dashboardPageId: string,
    dashboardPage: DashboardPage,
    params?: DateParams,
    paramFilters?: ParamFilter[],
    maxWidth?: number,
    dataSources: DataSource[]
}) {

    const dashboardState = useDashboardStateContext();
    const snackbarController = useSnackbarController();
    const dataki = useDataki();

    if (!dashboardPage) {
        throw new Error("FilterSuggestionView INTERNAL: No dashboard page found");
    }

    const filter = {
        ...suggestion.filter,
        position: {
            x: 0,
            y: 0,
        },
        dataSources,
    } satisfies DashboardFilterConfig;
    const filterHasBeenAdded = Boolean(dashboardPage.filters?.find(f => f.key === filter.key));

    const filterKey = filter.key;

    const existingParamFilter = paramFilters?.find(f => f.key === filterKey);

    const [value, setValue] = React.useState<FilterValue | null>(existingParamFilter?.value ?? null);
    const [operator, setOperator] = React.useState<FilterOp | null>(existingParamFilter?.operator ?? null);

    useEffect(() => {
        if (existingParamFilter) {
            setValue(existingParamFilter?.value ?? null);
            setOperator(existingParamFilter?.operator ?? null);
        } else {
            setValue(null);
            setOperator(null);
        }
    }, [existingParamFilter]);

    const onChange = (v: FilterValue | undefined, o: FilterOp | undefined) => {
        setValue(v ?? null);
        setOperator(o ?? null);
        const updatedParamFilter: ParamFilter = {
            key: filterKey,
            value: v ?? null,
            operator: o ?? undefined,
            type: filter.type,
        }
        dashboardState.setParamFilters((paramFilters ?? []).map(f => {
            if (f.key === filterKey) {
                return updatedParamFilter;
            }
            return f;
        }));
    };
    const addFilterToDashboard = () => {
        if (!filterHasBeenAdded) {
            const dashboardFilter: DashboardFilterConfig = {
                ...filter,
                dataSources,
                position: {
                    x: 0,
                    y: 0,
                }
            };
            dataki.addFilterToDashboard(dashboardId, dashboardPageId, dashboardFilter)
                .then(() => {
                    snackbarController.open({
                        message: "Filter added to dashboard",
                        type: "success",
                        autoHideDuration: 1000
                    });
                });
        }
    };

    const addFilterAndUpdateWidgets = () => {
        if (!filterHasBeenAdded) {
            const dashboardFilter: DashboardFilterConfig = {
                ...filter,
                dataSources,
                position: {
                    x: 0,
                    y: 0,
                }
            };

            // apply all suggestion.widgetUpdates
            const updates = suggestion.widgetUpdates.map(update => {
                const widget = dashboardPage.widgets
                    .find(w => w.id === update.widgetId) as DashboardWidgetConfig;
                if (widget?.type !== "chart" && widget?.type !== "table") {
                    throw new Error("FilterSuggestionView INTERNAL: No widget found " + update.widgetId);
                }
                const updatedWidget: DashboardWidgetConfig = {
                    ...widget,
                    ...update.delta
                };
                return updatedWidget;
            });

            // replace the updated widgets in the dashboard page
            const updatedWidgets = dashboardPage.widgets.map(widget => {
                const update = updates.find(u => u.id === widget.id);
                if (update) {
                    return update;
                }
                return widget;
            });
            const updatedDashboardPage: DashboardPage = {
                ...dashboardPage,
                widgets: updatedWidgets,
                filters: [...(dashboardPage.filters ?? []), dashboardFilter]
            };

        }
    }
    return <ErrorBoundary>
        <Paper className={"p-4 w-full flex flex-col gap-2 my-4 bg-white dark:bg-surface-900 rounded-xl"}
               style={{ width: maxWidth }}>

            <Typography variant={"subtitle2"}>
                Filter suggestion
            </Typography>

            <Typography variant={"body2"} className={"mb-2"}>
                Generating a filter with the parameter: <code>{filter.key}</code>
            </Typography>

            <FilterView filter={filter}
                        onFilterUpdate={(filter) => {
                            onSuggestionUpdate?.({
                                ...suggestion,
                                filter: {
                                    ...filter,
                                }
                            });
                        }}
                        dataSources={dataSources}
                        value={value}
                        operator={operator ?? undefined}
                        onChange={onChange}/>

            <Button variant={"text"}
                    size={"small"}
                    className={"mr-4"}
                    disabled={filterHasBeenAdded}
                    onClick={addFilterToDashboard}>
                Add filter to this dashboard page
            </Button>

            <Typography variant={"label"} className={"mt-4 mb-2"}>
                You need to update the SQL config of these widgets in order to support the new
                filter:
            </Typography>

            {(suggestion.widgetUpdates ?? []).map((update) => {
                const widget = dashboardPage.widgets
                    .find(w => w.id === update.widgetId) as DashboardWidgetConfig;
                if (widget?.type !== "chart" && widget?.type !== "table") {
                    throw new Error("FilterSuggestionView INTERNAL: No widget found " + update.widgetId);
                }
                return <WidgetUpdateSuggestion
                    dashboardId={dashboardId}
                    dashboardPageId={dashboardPageId}
                    key={update.widgetId}
                    enabled={filterHasBeenAdded}
                    widget={widget}
                    update={update}
                    onUpdate={() => {
                        if (!widget.id) {
                            throw new Error("FilterSuggestionView INTERNAL: No widget id found");
                        }
                        const updatedWidget: DashboardWidgetConfig = {
                            ...widget,
                            ...update.delta
                        };
                        dataki.onWidgetUpdate(dashboardId, dashboardPageId, widget.id, updatedWidget);
                    }}/>
            })}

            {/*<Button variant={"text"}*/}
            {/*        size={"small"}*/}
            {/*        className={"mr-4"}*/}
            {/*        disabled={filterHasBeenAdded}*/}
            {/*        onClick={() => {*/}
            {/*            if (!filterHasBeenAdded) {*/}
            {/*                const dashboardFilter: DashboardFilterConfig = {*/}
            {/*                    ...filter,*/}
            {/*                    dataSources,*/}
            {/*                    position: {*/}
            {/*                        x: 0,*/}
            {/*                        y: 0,*/}
            {/*                    }*/}
            {/*                };*/}
            {/*                dataki.addFilterToDashboard(dashboardId, dashboardPageId, dashboardFilter)*/}
            {/*                    .then(() => {*/}
            {/*                        snackbarController.open({*/}
            {/*                            message: "Filter added to dashboard",*/}
            {/*                            type: "success",*/}
            {/*                            autoHideDuration: 1000*/}
            {/*                        });*/}
            {/*                    });*/}
            {/*            }*/}
            {/*        }}>*/}
            {/*    Add filter to this dashboard page and update widgets*/}
            {/*</Button>*/}

        </Paper>
    </ErrorBoundary>
}

function WidgetUpdateSuggestion(props: {
    dashboardId: string,
    dashboardPageId: string,
    widget: DashboardWidgetConfig,
    update: { widgetId: string; delta: Partial<DryChartWidgetConfig | DryTableWidgetConfig> },
    enabled: boolean,
    onUpdate?: () => void
}) {

    const dataki = useDataki();

    const differentFromCurrent = React.useMemo(() => {
        return Object.entries(props.update.delta).some(([key, value]) => {
            const dataSources = props.widget.dataSources;
            const dialect = getDialectFromDataSources(dataSources);
            if (key === "sql") {
                return formatSQL(value, dialect) !== formatSQL(props.widget.sql, dialect);
            }
            return false;
        });
    }, [props.widget.sql, props.update.delta]);

    const [expanded, setExpanded] = React.useState(false);

    return <Paper className={"flex flex-col p-0 rounded-xl dark:bg-surface-900 bg-white"}>
        <div className={"flex flex-row items-center gap-2"}>
            <div className={"p-4 flex-1 flex-shrink"}>
                <Typography variant={"label"}>{props.widget.title}</Typography>
                <Typography variant={"caption"} color={"secondary"}>{props.update.widgetId}</Typography>
            </div>
            <IconButton size={"small"} className={expanded ? "rotate-180" : ""}
                        onClick={() => setExpanded(prev => !prev)}>
                <ExpandMoreIcon/>
            </IconButton>

            <Tooltip
                title={!props.enabled ? "Please add the filter to the dashboard first" : "This button is enabled whenever the suggested SQL is different from the current one"}>
                <Button variant={"text"}
                        size={"small"}
                        className={"mr-4"}
                        disabled={!differentFromCurrent || !props.enabled}
                        onClick={props.onUpdate}>
                    Update SQL
                </Button>
            </Tooltip>
        </div>

        <div
            className="overflow-hidden transition-all duration-300 ease-in-out"
            style={{
                maxHeight: expanded ? "1000px" : "0px",
                opacity: expanded ? 1 : 0
            }}>

            {props.update.delta.sql && <SQLPreview sqlQuery={props.update.delta.sql}
                                                   className={"h-full overflow-auto rounded-b-xl p-4 bg-slate-200 dark:bg-slate-800"}
            />}
        </div>
    </Paper>;
}
