import { AutoAwesomeIcon, Avatar, cls, Menu, MenuItem, PersonIcon, StorageIcon } from "@firecms/ui";
import React, { useEffect, useRef, useState } from "react";
import {
    ChatMessage,
    DashboardPage, DashboardWidgetConfig,
    DryChartWidgetConfig, DryTableWidgetConfig,
    DryWidgetConfig,
    FeedbackSlug,
    FunctionCall,
    SQLDialect
} from "../../types";
import { SystemMessage } from "./SystemMessage";
// @ts-ignore
import MarkdownIt from "markdown-it";
import { SQLPreview } from "../SQLPreview";

export function MessageView({
                                message,
                                onRemove,
                                onRegenerate,
                                canRegenerate,
                                onFeedback,
                                onUpdatedMessage,
                                dialect,
                                maxWidth,
                                dashboardPage,
                                dashboardId,
                                dashboardPageId,
                                onDashboardWidgetUpdated
                            }: {
    message?: ChatMessage,
    onRemove?: () => void,
    onRegenerate?: () => void,
    canRegenerate?: boolean,
    onFeedback?: (reason?: FeedbackSlug, feedbackMessage?: string) => void,
    onUpdatedMessage?: (message: ChatMessage) => void,
    dialect: SQLDialect,
    maxWidth?: number,
    dashboardPage?: DashboardPage,
    dashboardId?: string,
    dashboardPageId?: string,
    onDashboardWidgetUpdated?: (config: DashboardWidgetConfig) => void
}) {

    const ref = useRef<HTMLDivElement>(null);

    const onUpdatedMessageInternal = (updatedText: string) => {
        if (!message) return;
        if (onUpdatedMessage) onUpdatedMessage({
            ...message,
            text: updatedText
        });
    }
    const onUpdatedFunctionCall = (functionCall: FunctionCall) => {
        console.log("onUpdatedFunctionCall", functionCall);
        if (!message) return;
        if (onUpdatedMessage) onUpdatedMessage({
            ...message,
            text: functionCall.params.sql,
            function_call: functionCall
        });
    }

    const [containerWidth, setContainerWidth] = useState<number | null>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (ref.current) {
                const rect = ref.current?.getBoundingClientRect();
                setContainerWidth(rect.width);
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => resizeObserver.disconnect();
    }, [ref]);

    const bgColor = message?.user === "FUNCTION_CALL"
        ? "bg-slate-200 dark:bg-slate-800"
        : (message?.user === "SYSTEM" ? "bg-transparent dark:bg-transparent" : "bg-white dark:bg-surface-800 dark:bg-opacity-20");

    const padding = message?.user === "FUNCTION_CALL" ? "py-1 px-4" : "p-4";

    return <div ref={ref}
                className={cls("flex flex-col gap-2 rounded-3xl", bgColor, padding)}>
        <div className="flex items-start gap-3 justify-center">
            <Menu
                trigger={<Avatar
                    outerClassName={"shrink-0"}
                    className="w-10 h-10">
                    {message?.user === "USER" && <PersonIcon/>}
                    {message?.user === "SYSTEM" && <AutoAwesomeIcon/>}
                    {message?.user === "FUNCTION_CALL" && <StorageIcon/>}
                </Avatar>}>
                <MenuItem dense onClick={onRemove}>Remove</MenuItem>
            </Menu>

            <div
                style={{ maxWidth: maxWidth ?? "100%" }}
                className={cls(message?.user === "FUNCTION_CALL" ? "my-1" : "mt-3 mb-1 min-h-[32px]",
                    "flex-1 text-surface-700 dark:text-surface-200 self-center")}>

                {message?.user === "USER" && <UserMessage text={message.text}
                                                          maxWidth={containerWidth ? (containerWidth - 90) : undefined}/>}
                {message?.user === "FUNCTION_CALL" && message.function_call &&
                    <FunctionCallMessage text={message.text}
                                         functionCall={message.function_call}
                                         maxWidth={containerWidth ? (containerWidth - 90) : undefined}
                                         onUpdatedFunctionCall={onUpdatedFunctionCall}/>}
                {message?.user === "SYSTEM" && <SystemMessage
                    key={message.id}
                    text={message.text}
                    loading={message.loading}
                    canRegenerate={canRegenerate}
                    maxWidth={containerWidth ? (containerWidth - 90) : undefined}
                    onRegenerate={onRegenerate}
                    onUpdatedMessage={onUpdatedMessageInternal}
                    onFeedback={onFeedback}
                    dialect={dialect}
                    dashboardPage={dashboardPage}
                    dashboardId={dashboardId}
                    onDashboardWidgetUpdated={onDashboardWidgetUpdated}
                    dashboardPageId={dashboardPageId}/>}

            </div>
        </div>
    </div>;
}

const md = new MarkdownIt({ html: true });

function UserMessage({
                         text,
                         maxWidth
                     }: { text: string, maxWidth?: number }) {
    const [html, setHtml] = useState<string | null>(null);
    useEffect(() => {
        setHtml(md.render(text));
    }, [text]);
    if (!html)
        return null;
    return <div
        style={{ maxWidth: maxWidth ?? "100%" }}
        className={"w-full prose dark:prose-invert prose-headings:font-title text-base text-surface-700 dark:text-surface-200 mb-3"}
        dangerouslySetInnerHTML={{ __html: html }}/>
    // return <>{text.split("\n").map((line, index) => <p key={index}>{line}</p>)}</>
}

function FunctionCallMessage({
                                 text,
                                 functionCall,
                                 onUpdatedFunctionCall,
                                 maxWidth
                             }: {
    text: string,
    functionCall: FunctionCall,
    onUpdatedFunctionCall: (updatedFunctionCall: FunctionCall) => void,
    maxWidth?: number
}) {

    if (!text)
        return null;

    return <>
        <SQLPreview sqlQuery={text}
                    maxWidth={maxWidth}
                    onSaved={async (sql) => {
                        if (sql) {
                            const updatedFunctionCall = {
                                ...functionCall,
                                params: {
                                    ...functionCall.params,
                                    sql
                                }
                            };
                            onUpdatedFunctionCall(updatedFunctionCall)
                        }
                    }}/>

    </>

}
