import React, { useDeferredValue, useEffect, useRef } from "react";
import {
    Button,
    cls,
    ForumIcon,
    IconButton,
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarPortal,
    MenubarSeparator,
    MenubarShortcut,
    MenubarTrigger,
    ShareIcon,
    TextField,
    TitleIcon,
    Tooltip
} from "@firecms/ui";
import { Dashboard } from "../../types";
import { DownloadButton } from "./DownloadButton";
import { useDataki } from "../../DatakiProvider";
import { DashboardState } from "../../hooks/useCreateDashboardState";
import { randomString } from "@firecms/core";
import { DEFAULT_GRID_SIZE, SUBTITLE_HEIGHT, TEXT_WIDTH, TITLE_HEIGHT } from "../../utils/widgets";
import { ReactFlowInstance } from "@xyflow/react";

const RADIO_ITEMS = ["Andy", "Benoît", "Luis"];
const CHECK_ITEMS = ["Always Show Bookmarks Bar", "Always Show Full URLs"];

export const DashboardMenubar = ({
                                     dashboard,
                                     dashboardState,
                                     onSharedClick,
                                     onNewWidgetClick,
                                     reactFlow,
                                     className,
                                     readOnly
                                 }: {
    dashboard: Dashboard,
    dashboardState: DashboardState,
    onSharedClick: () => void,
    onNewWidgetClick: () => void,
    reactFlow: ReactFlowInstance,
    className?: string,
    readOnly: boolean
}) => {

    const datakiConfig = useDataki();

    const viewport = reactFlow.getViewport();

    const [checkedSelection, setCheckedSelection] = React.useState([CHECK_ITEMS[1]]);
    const [radioSelection, setRadioSelection] = React.useState(RADIO_ITEMS[2]);

    return (
        <Menubar
            className={cls("flex-1 z-10 flex items-center bg-transparent dark:bg-transparent rounded-2xl gap-0 px-2 shadow-none py-0", className)}>

            <DashboardNameTextField readOnly={readOnly}
                                    title={dashboard.title}
                                    id={dashboard.id}/>

            {!readOnly && <div className={"flex items-center gap-0 px-0 mx-2"}>
                <Tooltip title={"Download as .png"}>
                    <DownloadButton reactFlow={reactFlow}/>
                </Tooltip>
                <Tooltip title={"Share this dashboard"}>
                    <IconButton variant="ghost">
                        <ShareIcon onClick={onSharedClick}/>
                    </IconButton>
                </Tooltip>
            </div>}
            {/*<Separator orientation={"vertical"}/>*/}

            {!readOnly && <div className={"flex items-center gap-0 px-0 mx-2"}>
                <Tooltip title={"Add title"}>
                    <IconButton variant="ghost"
                                onClick={() => {
                                    datakiConfig.addDashboardText(dashboard.id, dashboard.pages[0].id, {
                                        id: randomString(20),
                                        type: "title",
                                        text: "",
                                        position: {
                                            x: DEFAULT_GRID_SIZE,
                                            y: roundDownToGrid(-viewport.y, DEFAULT_GRID_SIZE) + 100
                                        },
                                        size: {
                                            width: TEXT_WIDTH,
                                            height: TITLE_HEIGHT
                                        }
                                    });
                                }}>
                        <TitleIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Add heading"}>
                    <IconButton variant="ghost"
                                onClick={() => {
                                    datakiConfig.addDashboardText(dashboard.id, dashboard.pages[0].id, {
                                        id: randomString(20),
                                        type: "subtitle",
                                        text: "",
                                        position: {
                                            x: DEFAULT_GRID_SIZE,
                                            y: roundDownToGrid(-viewport.y, DEFAULT_GRID_SIZE) + 100
                                        },
                                        size: {
                                            width: TEXT_WIDTH,
                                            height: SUBTITLE_HEIGHT
                                        }
                                    });
                                }}>
                        <TitleIcon size={"small"}/>
                    </IconButton>
                </Tooltip>
            </div>}
            {/*<Separator orientation={"vertical"}/>*/}

            {!readOnly && <MenubarMenu>
                <MenubarTrigger className={"rounded-xl"}>
                    Edit
                </MenubarTrigger>
                <MenubarPortal>
                    <MenubarContent>
                        <MenubarItem disabled={!dashboardState.canCopy}
                                     onSelect={dashboardState.onCopy}>
                            Copy{" "}
                            <MenubarShortcut>
                                ⌘ C
                            </MenubarShortcut>
                        </MenubarItem>
                        <MenubarItem disabled={!dashboardState.canPaste}
                                     onSelect={dashboardState.onPaste}>
                            Paste{" "}
                            <MenubarShortcut>
                                ⌘ V
                            </MenubarShortcut>
                        </MenubarItem>
                        <MenubarSeparator/>
                        <MenubarItem disabled={!dashboardState.canUndo}
                                     onSelect={dashboardState.onUndo}>
                            Undo{" "}
                            <MenubarShortcut>
                                ⌘ Z
                            </MenubarShortcut>
                        </MenubarItem>
                        <MenubarItem disabled={!dashboardState.canRedo}
                                     onSelect={dashboardState.onRedo}>
                            Redo{" "}
                            <MenubarShortcut>
                                ⇧ ⌘ Z
                            </MenubarShortcut>
                        </MenubarItem>
                    </MenubarContent>
                </MenubarPortal>
            </MenubarMenu>}

            {!readOnly && <Button
                variant={"text"}
                className={"rounded-xl"}
                onClick={onNewWidgetClick}>
                <ForumIcon size={"small"}/>
                Edit
            </Button>}

        </Menubar>
    );
};

function DashboardNameTextField({
                                    title: titleProp,
                                    id,
                                    readOnly
                                }: { title?: string, id: string, readOnly: boolean }) {
    const datakiConfig = useDataki();
    const savedTitle = useRef(titleProp);
    const [title, setTitle] = React.useState(titleProp);
    const deferredTitle = useDeferredValue(title);
    useEffect(() => {
        if (deferredTitle !== savedTitle.current) {
            datakiConfig.updateDashboard(id, { title: deferredTitle }, "title_update");
            savedTitle.current = deferredTitle;
        }
    }, [deferredTitle]);
    return (
        <TextField
            className={"font-semibold rounded-xl text-sm w-64"}
            inputClassName={"rounded-xl"}
            // invisible={true}
            disabled={readOnly}
            size={"small"}
            placeholder={"Untitled dashboard"}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
        />
    );
}

function roundDownToGrid(num: number, gridSize: number): number {
    return Math.floor(num / gridSize) * gridSize;
}
