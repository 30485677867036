import React, { useEffect } from "react";
import {
    ChatMessage,
    ChatSession,
    Dashboard,
    DashboardFilterConfig,
    DashboardWidgetConfig,
    DataSource,
    ParamFilter
} from "../types";
import { DashboardPanel } from "./DashboardPanel";
import { DatakiChatSession } from "./chat/DatakiChatSession";
import { useDataki } from "../DatakiProvider";
import { useLocation, useNavigate } from "react-router";
import { CircularProgressCenter, ErrorBoundary } from "@firecms/core";
import { AccessTimeIcon, AddIcon, Chip, cls, IconButton, Typography } from "@firecms/ui";
import { ChatHistory } from "./ChatHistory";
import { ReactFlowInstance } from "@xyflow/react";

export function DashboardChatView({
                                      dashboardId,
                                      dashboardPageId,
                                      dashboard,
                                      onClose,
                                      dateRange,
                                      paramFilters,
                                      filters,
                                      hidden,
                                      reactFlow
                                  }: {
    dashboardId: string,
    dashboardPageId: string,
    dashboard: Dashboard,
    onClose?: () => void,
    dateRange: [Date | null, Date | null],
    paramFilters: ParamFilter[],
    filters: DashboardFilterConfig[],
    hidden?: boolean,
    reactFlow: ReactFlowInstance
}) {

    const datakiConfig = useDataki();
    const navigate = useNavigate();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);
    const initialPrompt = urlSearchParams.get("prompt");

    const dashboardPage = dashboard.pages.find(p => p.id === dashboardPageId);
    if (!dashboardPage) {
        throw new Error("DashboardChatView INTERNAL: No dashboard page found");
    }

    const [mode, setMode] = React.useState<"chat" | "chat_history">("chat");

    const [selectedSession, setSelectedSession] = React.useState<ChatSession | undefined>(undefined);

    const [sessions, setSessions] = React.useState<ChatSession[] | undefined>(undefined);
    const [loading, setLoading] = React.useState(true);

    // hold the data source dialog open state in the URL
    // so that the user can refresh the page and the dialog remains open
    const initialDataSourceSelectionOpen = urlSearchParams.get("dataSource") === "true";

    async function goToNewChat() {
        const chatId = await datakiConfig.createChatSessionId();
        setSelectedSession(createNewSession(chatId, dashboardId));
    }

    useEffect(() => {
        return datakiConfig.listenChatSessions(
            {
                dashboardId,
                onChatSessionsUpdate: async (updatedSessions) => {

                    setSessions(updatedSessions);
                    if (updatedSessions.length > 0) {
                        setSelectedSession(updatedSessions[0]);
                    } else {
                        await goToNewChat();
                    }
                    setLoading(false);
                }
            });
    }, [dashboardId]);

    if (loading) {
        return <CircularProgressCenter/>
    }

    if (!selectedSession) {
        throw new Error("INTERNAL: No session id");
    }

    const usedSession = selectedSession;

    const onMessagesChange = (messages: ChatMessage[]) => {
        const newSession = {
            ...usedSession,
            messages
        };
        setSelectedSession(newSession);
        datakiConfig.saveChatSession(newSession);
    };

    const onDataSourcesChange = (dataSources: DataSource[]) => {
        const newSession = {
            ...usedSession,
            dataSources
        };
        setSelectedSession(newSession);
        datakiConfig.saveChatSession(newSession);
    }

    const onProjectIdChange = (projectId: string) => {
        const newSession = {
            ...usedSession,
            projectId
        };
        setSelectedSession(newSession);
        datakiConfig.saveChatSession(newSession);
    }

    const onDashboardWidgetUpdated = (widget: DashboardWidgetConfig) => {
        // if we are on a dashboard
        if (dashboardId) {
            setTimeout(() => {
                reactFlow.fitView({
                    duration: 500,
                    maxZoom: 1,
                    nodes: [widget],
                    padding: 0.4
                });
            }, 300);
        }
    }

    return <DashboardPanel onClose={onClose}
                           title={<>
                               <Typography variant={"label"} className={"flex-grow"}>
                                   Chat
                               </Typography>
                               <Chip size={"small"}>{selectedSession.id}</Chip>
                           </>}
                           className={cls({ hidden })}
                           endComponent={<>
                               <IconButton size={"small"}
                                           onClick={async () => {
                                               setMode("chat")
                                               goToNewChat();
                                           }}>
                                   <AddIcon size={"small"}/>
                               </IconButton>
                               <IconButton size={"small"} onClick={() => {
                                   setMode("chat_history");
                               }}>
                                   <AccessTimeIcon size={"small"}/>
                               </IconButton>
                           </>}>

        {mode === "chat" && <ErrorBoundary>
            <DatakiChatSession
                key={selectedSession.id}
                // onAnalyticsEvent={onAnalyticsEvent}
                className={"bg-gray-50 dark:bg-surface-900"}
                session={usedSession}
                initialPrompt={initialPrompt ?? undefined}
                onDataSourcesChange={onDataSourcesChange}
                onProjectIdChange={onProjectIdChange}
                onMessagesChange={onMessagesChange}
                onDashboardWidgetUpdated={onDashboardWidgetUpdated}
                padding={false}
                initialDataSourceSelectionOpen={initialDataSourceSelectionOpen}
                dashboardId={dashboardId}
                dashboardPageId={dashboardPageId}
                dashboardPage={dashboardPage}
                onDataSourceSelectionOpenChange={(open) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (open) searchParams.set("dataSource", "true");
                    else searchParams.delete("dataSource");
                    navigate({
                        search: searchParams.toString()
                    }, { replace: true });
                }}
                dateRange={dateRange}
                paramFilters={paramFilters}
                filters={filters}
            />
        </ErrorBoundary>}

        {mode === "chat_history" && <ChatHistory dashboardId={dashboardId}
                                                 onNewChatClick={async () => {
                                                     setMode("chat");
                                                     goToNewChat();
                                                 }}
                                                 onEntryClick={(session) => {
                                                     setMode("chat");
                                                     setSelectedSession(session);
                                                 }}/>}
    </DashboardPanel>;
}

function createNewSession(chatId: string, dashboardId: string): ChatSession {
    return {
        id: chatId,
        dashboardId,
        created_at: new Date(),
        updated_at: new Date(),
        messages: [],
        dataSources: []
    } satisfies ChatSession;
}
