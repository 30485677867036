import { DataSource, SQLDialect } from "../types";
import { format } from "sql-formatter";

export function getDialectFromDataSources(dataSources: DataSource[]): SQLDialect {
    // TODO
    return "bigquery";
}

export function formatSQL(sql: string, dialect: SQLDialect) {
    try {
        return format(sql, { language: dialect });
    } catch (e) {
        console.error("Error formatting SQL", e);
        return sql;
    }
}
