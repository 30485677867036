import React from "react";
import { CellRendererParams, VirtualTable, VirtualTableColumn } from "@firecms/core";
import { getIn } from "@firecms/formex";
import { DataRow, DataType, TableColumn } from "../types";
import { DataTableCell } from "./DataTableCell";

export type DataTableProps = {
    columns: TableColumn[];
    data?: DataRow[];
    zoom?: number;
    maxWidth?: number;
    ref: React.RefObject<HTMLDivElement | null>,
    onEndReached?: () => void;
    onColumnResize?: (params: { key: string, width: number }) => void;
    loading?: boolean;
}

export function DataTable({
                              data,
                              columns,
                              ref,
                              zoom = 1,
                              onColumnResize,
                              maxWidth,
                              onEndReached,
                              loading
                          }: DataTableProps) {

    function cellRenderer({
                              columns,
                              column,
                              columnIndex,
                              rowData,
                              rowIndex,
                              isScrolling
                          }: CellRendererParams) {

        const entry = getIn(rowData, column.key);
        return <DataTableCell
            align={column.align}
            width={column.width}>
            {entry}
        </DataTableCell>;
    }

    const tableColumns: VirtualTableColumn[] = columns.map(col => {
        return {
            key: col.key,
            title: col.name,
            width: col.width ?? getColumnWidth(col.dataType),
            resizable: true,
        };
    });

    return (
        <>
            <div className="nowheel nodrag flex h-full w-full flex-col bg-white dark:bg-surface-950"
                 style={{
                     maxWidth
                 }}
                 ref={ref}>

                <VirtualTable
                    loading={loading}
                    data={data}
                    rowHeight={48}
                    columns={tableColumns}
                    cellRenderer={cellRenderer}
                    onColumnResize={onColumnResize}
                    onEndReached={onEndReached}
                    endOffset={1600}
                />

            </div>

        </>
    );

};

function getColumnWidth(dataType?: DataType) {
    switch (dataType) {
        case "object":
            return 300;
        case "string":
            return 300;
        case "number":
            return 180;
        case "date":
            return 240;
        case "array":
            return 240;
        default:
            return 200;
    }
}
