import { cls, defaultBorderMixin, Select, SelectItem, Tooltip } from "@firecms/ui";
import { Model } from "../types";

export function ModelSelector({
                                  model,
                                  setModel,
                                  className
                              }: {
    model: Model,
    setModel: (model: Model) => void;
    className?: string;
}) {
    return <Select
        size={"small"}
        className={cls("border rounded-xl", defaultBorderMixin, className)}
        inputClassName={"bg-gray-100 dark:bg-surface-950 rounded-xl"}
        value={model}
        renderValue={(value) => {
            if (value === "gemini-2.5-pro-exp-03-25") return "Gemini 2.5 Pro";
            if (value === "gemini-2.0-flash") return "Gemini 2.0 Flash";
            if (value === "gpt-4o") return "GPT-4o";
            if (value === "o3-mini") return "O3 mini";
            return value;
        }}
        onChange={(e) => setModel(e.target.value as Model)}>
        <Tooltip title={"RECOMMENDED Fast and good at generating simple charts"} side={"left"}>
            <SelectItem value={"gemini-2.0-flash"}>Gemini 2.0 Flash</SelectItem>
        </Tooltip>
        <Tooltip title={"This model is experimental and its rate its very limited"} side={"left"}>
            <SelectItem value={"gemini-2.5-pro-exp-03-25"}>Gemini 2.5 Pro Exp (rate limited)</SelectItem>
        </Tooltip>
        <Tooltip title={"Better for generating SQL, and able to test it by running it"} side={"left"}>
            <SelectItem value={"gpt-4o"}>GPT-4o</SelectItem>
        </Tooltip>
        <Tooltip title={"Fast and good at generating simple charts"} side={"left"}>
            <SelectItem value={"o3-mini"}>O3 mini</SelectItem>
        </Tooltip>
    </Select>;
}
