import React, { ComponentType, memo, useEffect, useState } from "react";
import { Node, NodeProps, NodeResizer } from "@xyflow/react";
import { TextItem, WidgetSize } from "../../../types";
import { cls, IconButton, RemoveIcon, TextField, Tooltip, useDebounceValue } from "@firecms/ui";
import { useDataki } from "../../../DatakiProvider";
import { TEXT_WIDTH, TITLE_HEIGHT } from "../../../utils/widgets";
import { useDashboardStateContext } from "../DashboardPageView";

export type TextNodeProps = {
    widget: TextItem;
    dashboardId: string;
    pageId: string;
};

function TextNode(props: NodeProps<Node<TextNodeProps>>) {

    const dashboardState = useDashboardStateContext();

    const { data } = props;

    const [text, setText] = useState<string>(data.widget.text);
    const [size, setSize] = useState<WidgetSize>(data.widget?.size ?? {
        width: TEXT_WIDTH,
        height: TITLE_HEIGHT
    });

    const deferredText = useDebounceValue(text);
    useEffect(() => {
        if (deferredText !== data.widget.text) {
            datakiConfig.updateDashboardText(data.dashboardId, data.pageId, data.widget.id, {
                ...data.widget,
                text: deferredText
            });
        }
    }, [deferredText]);

    const datakiConfig = useDataki();

    const [resizing, setResizing] = useState<boolean>(false);
    let textClass: string;
    if (data.widget.type === "title") {
        textClass = "text-3xl";
    } else if (data.widget.type === "subtitle") {
        textClass = "text-xl";
    } else {
        textClass = "text-body";
    }

    const fieldSize = data.widget.type === "title" ? "medium" : "small";

    return (
        <div className={cls(textClass, "relative group")}
             style={{
                 width: size.width,
                 height: size.height
             }}>

            {!dashboardState.readOnly && <Tooltip title={"Remove this view"}
                                                  className={cls("absolute -top-4 -right-4 h-[32px] rounded-full bg-surface-50 dark:bg-surface-950 group-hover:visible z-10",
                                                      props.selected ? "visible" : "invisible")}>
                <IconButton
                    onClick={() => datakiConfig.onWidgetRemove(data.dashboardId, data.pageId, data.widget.id)}
                    size={"small"}>
                    <RemoveIcon
                        size={"small"}/>
                </IconButton>
            </Tooltip>}

            {dashboardState.readOnly ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: "8px 12px",
                        overflow: "auto"
                    }}
                    className={cls(
                        "overflow-auto",
                        resizing ? "bg-opacity-70 dark:bg-surface-700 dark:bg-opacity-60 dark:hover:bg-opacity-60" : "",
                        props.selected || resizing ? "ring-offset-transparent ring-2 ring-primary ring-opacity-75 ring-offset-2" : ""
                    )}>
                    {text || ""}
                </div>
            ) : (
                <TextField value={text}
                           size={fieldSize}
                           style={{
                               width: "100%",
                               height: "100%"
                           }}
                           inputClassName={"h-full"}
                           invisible={!props.selected}
                           placeholder={"Write something"}
                           className={cls(resizing ? "bg-opacity-70 dark:bg-surface-700 dark:bg-opacity-60 dark:hover:bg-opacity-60" : "",
                               props.selected || resizing ? "ring-offset-transparent ring-2 ring-primary ring-opacity-75 ring-offset-2" : "")}
                           onChange={(e) => {
                               setText(e.target.value);
                           }}/>
            )}

            {!dashboardState.readOnly && <NodeResizer minWidth={200}
                                                      minHeight={size.height}
                                                      maxHeight={size.height}
                                                      shouldResize={(event, params) => {
                                                          return params.direction[0] !== 0;
                                                      }}
                                                      onResizeStart={() => setResizing(true)}
                                                      onResizeEnd={(event, params) => {
                                                          const updatedSize = {
                                                              width: params.width,
                                                              height: params.height
                                                          };
                                                          const position = {
                                                              x: params.x,
                                                              y: params.y
                                                          };

                                                          console.log("onResizeEnd", data.widget.id, updatedSize, position);
                                                          dashboardState.onNodeResize(data.widget.id, {
                                                              size: updatedSize,
                                                              position
                                                          });
                                                          setSize(updatedSize);
                                                          setResizing(false);
                                                      }}
                                                      onResize={(event, params) => {
                                                          console.log("onResize", params);
                                                          const updatedSize = {
                                                              width: params.width,
                                                              height: params.height
                                                          };

                                                          setSize(updatedSize);
                                                      }}
            />}

        </div>
    );
}

export default memo(TextNode) as ComponentType<NodeProps<Node<TextNodeProps>>>;
