import {
    Avatar,
    CloseIcon,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LoadingButton,
    Select,
    SelectItem,
    TextField
} from "@firecms/ui";
import React, { useEffect } from "react"
import { Dashboard, DatakiUser } from "../types";
import { useDataki } from "../DatakiProvider";
import { deleteUserFromDashboard, inviteUserToDashboard } from "../api";
import { useAuthController, useSnackbarController } from "@firecms/core";

interface ShareDialogProps {
    dashboard: Dashboard;
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export const ShareDialog: React.FC<ShareDialogProps> = ({
                                                            dashboard,
                                                            open,
                                                            onOpenChange
                                                        }) => {

    const dataki = useDataki();
    const authController = useAuthController();
    const snackbarController = useSnackbarController();

    const [dashboardUsers, setDashboardUsers] = React.useState<(DatakiUser & { type: "read" | "write" })[]>([]);
    const [removingUser, setRemovingUser] = React.useState(false);

    const removeUser = async (email: string) => {
        setRemovingUser(true);
        const firebaseToken = await dataki.getAuthToken();
        deleteUserFromDashboard(email,
            dashboard.id,
            firebaseToken,
            dataki.apiEndpoint)
            .then(() => {
                setDashboardUsers(dashboardUsers.filter(u => u.email !== email));
                snackbarController.open({
                    message: "User removed successfully",
                    type: "success"
                });
            })
            .catch((e) => {
                console.error(e);
                snackbarController.open({
                    message: e.message ?? "Error removing user",
                    type: "error"
                });
            })
            .finally(() => setRemovingUser(false));
    }

    const loggedUserInProject = authController.user?.uid
        ? dashboardUsers?.find(u => u.id === authController.user?.uid)
        : undefined;
    const canUserEditPermissions = authController.user?.uid === dashboard.owner || loggedUserInProject?.type === "write";

    useEffect(() => {

        if (!dashboard.permissions) {
            setDashboardUsers([]);
            return;
        }
        Promise.all(
            dashboard.permissions.map(async ({
                                                 uid,
                                                 type
                                             }) => {
                const user = await dataki.getUser(uid);
                return {
                    ...user,
                    type
                };
            })
        ).then(setDashboardUsers);

    }, [dashboard.permissions]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange} maxWidth={"xl"}>

            <DialogTitle>
                Share this dashboard
                <div className="absolute top-4 right-4">
                    <IconButton variant="ghost"
                                onClick={() => onOpenChange(false)}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent className="relative flex flex-col gap-4">

                <InviteForm dashboard={dashboard}/>

                <div className="space-y-2 mt-4">
                    <div className="text-sm text-text-secondary dark:text-text-secondary-dark">Who has access</div>
                    <div>
                        {dashboardUsers.map((user) => {
                            const userIsOwner = user.id === dashboard.owner;
                            const userPermissionsComponent = userIsOwner || !canUserEditPermissions
                                ? <div
                                    className="text-sm text-text-disabled dark:text-text-disabled-dark">
                                    {userIsOwner
                                        ? "owner"
                                        : (user.type === "read" ? "can view" : "can edit")}
                                </div>
                                : <Select
                                    size={"small"}
                                    invisible={true}
                                    value={user.type}
                                    className="-mr-2"
                                    inputClassName={"px-0 pl-2"}
                                    onValueChange={(type) => {
                                        if (type === "remove") {
                                            return removeUser(user.email);
                                        } else if (type === "write" || type === "read") {
                                            return dataki.updateDashboardPermissions(dashboard.id, user.id, type as "read" | "write")
                                                .then(() => {
                                                    setDashboardUsers(dashboardUsers.map(u => {
                                                        if (u.id === user.id) {
                                                            return {
                                                                ...u,
                                                                type: type as "read" | "write"
                                                            };
                                                        }
                                                        return u;
                                                    }));
                                                });
                                        }
                                    }}>
                                    <SelectItem value={"write"}>Can edit</SelectItem>
                                    <SelectItem value={"read"}>Can view</SelectItem>
                                    <SelectItem value={"remove"}>Remove</SelectItem>
                                </Select>;
                            return (
                                <div key={user.id} className="flex items-center gap-2">
                                    <Avatar className="h-8 w-8" src={user.photoURL}
                                            alt={user.displayName ?? user.email}>
                                    </Avatar>
                                    <div className="flex-1">
                                        <div className="text-sm">{user.displayName ?? user.email}</div>
                                    </div>
                                    {userPermissionsComponent}
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/*<div className="space-y-2 pt-4">*/}
                {/*    <Button*/}
                {/*        variant="text"*/}
                {/*        color={"text"}*/}
                {/*        className="w-full justify-start gap-2">*/}
                {/*        <CodeIcon/>*/}
                {/*        Copy Dev Mode link*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </DialogContent>
        </Dialog>
    )
}

function InviteForm({
                        dashboard,
                    }: {
    dashboard: Dashboard,
}) {
    const {
        apiEndpoint,
        getAuthToken
    } = useDataki();

    const snackbarController = useSnackbarController();
    const [inviteLoading, setInviteLoading] = React.useState(false);
    const [permissions, setPermissions] = React.useState<"read" | "write">("write");

    const [email, setEmail] = React.useState("");

    const doInvite = async () => {
        setInviteLoading(true);
        const firebaseToken = await getAuthToken();
        inviteUserToDashboard(email,
            permissions,
            dashboard.id,
            firebaseToken,
            apiEndpoint)
            .then(() => {
                snackbarController.open({
                    message: "User invited successfully",
                    type: "success"
                });
            })
            .catch((e) => {
                console.error(e);
                snackbarController.open({
                    message: e.message ?? "Error inviting user",
                    type: "error"
                });
            })
            .finally(() => setInviteLoading(false));
    }

    return <form className="flex gap-2"
                 onSubmit={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     doInvite();
                     return false;
                 }}>
        <TextField
            size={"small"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Invite others by email"
            className="flex-grow"
            endAdornment={<Select
                size={"small"}
                value={permissions}
                onValueChange={(value) => {
                    if (value === "write" || value === "read")
                        setPermissions(value);
                }}>
                <SelectItem value={"write"}>Can edit</SelectItem>
                <SelectItem value={"read"}>Can view</SelectItem>
            </Select>}
        />
        <LoadingButton color="neutral"
                       loading={inviteLoading}
                       disabled={!email}
                       type="submit">
            Invite
        </LoadingButton>
    </form>;
}
