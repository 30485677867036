import React, { useEffect, useRef, useState } from "react";
import { DatakiConfig } from "../DatakiProvider";
import { CircularProgressCenter, useNavigationController } from "@firecms/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatakiChatSession } from "../components/chat/DatakiChatSession";
import { ChatMessage, ChatSession, DataSource } from "../types";
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { cls, defaultBorderMixin, DragHandleIcon, HistoryIcon, IconButton, Tooltip, Typography } from "@firecms/ui";
import { DashboardPanel } from "../components/DashboardPanel";
import { ChatHistory } from "../components/ChatHistory";
import { getSidePanelWidth, saveSidePanelWidth } from "../utils/side_panels";
import { getNewChatPath } from "../utils/navigation";

export function ChatSessionRoute({
                                     datakiConfig,
                                     onAnalyticsEvent,
                                 }: {
    datakiConfig: DatakiConfig,
    onAnalyticsEvent?: (event: string, params?: any) => void,
}) {

    const { sessionId } = useParams();
    if (!sessionId) throw Error("Session id not found");

    return <ChatRouteInner
        key={sessionId}
        sessionId={sessionId}
        datakiConfig={datakiConfig}
        onAnalyticsEvent={onAnalyticsEvent}/>;
}

interface ChatRouteInnerProps {
    sessionId: any;
    datakiConfig: DatakiConfig;
    onAnalyticsEvent?: (event: string, params?: any) => void,
}

function ChatRouteInner({
                            sessionId,
                            datakiConfig,
                            onAnalyticsEvent,
                        }: ChatRouteInnerProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const navigation = useNavigationController();

    const params = new URLSearchParams(location.search);
    const initialPrompt = params.get("prompt");

    const [session, setSession] = React.useState<ChatSession | undefined>(undefined);
    const [loading, setLoading] = React.useState(true);

    const [panelOpen, setPanelOpen] = useState<"chat_history" | null>(null);
    const sidePanelWidth = panelOpen ? getSidePanelWidth(panelOpen) : 0;

    // I need to hold the data source dialog open state in the URL
    // so that the user can refresh the page and the dialog remains open
    const initialDataSourceSelectionOpen = params.get("dataSource") === "true";

    useEffect(() => {
        setLoading(true);
        datakiConfig.getChatSession(sessionId)
            .then(session => {
                setSession(session);
                setLoading(false);
            });
    }, [sessionId]);

    const panelRef = useRef<ImperativePanelHandle>(null);

    useEffect(() => {
        if (panelOpen) {
            const sidePanelWidth = getSidePanelWidth(panelOpen);
            panelRef.current?.resize(sidePanelWidth);
        }
    }, [panelOpen]);

    if (loading) {
        return <CircularProgressCenter/>
    }

    const usedSession = session ?? {
        id: sessionId,
        dashboardId: null,
        created_at: new Date(),
        updated_at: new Date(),
        messages: [],
        dataSources: []
    } satisfies ChatSession;

    const onMessagesChange = (messages: ChatMessage[]) => {
        const newSession = {
            ...usedSession,
            messages
        };
        setSession(newSession);
        datakiConfig.saveChatSession(newSession);
    };

    const onDataSourcesChange = (dataSources: DataSource[]) => {
        const newSession = {
            ...usedSession,
            dataSources
        };
        setSession(newSession);
        datakiConfig.saveChatSession(newSession);
    }

    const onProjectIdChange = (projectId: string) => {
        const newSession = {
            ...usedSession,
            projectId
        };
        setSession(newSession);
        datakiConfig.saveChatSession(newSession);
    }

    /* TODO RANGE AND FILTERS OR REMOVE*/
    return (

        <div className={"flex w-full h-full relative"}>
            <div className={"flex-1 h-full"}
                 style={{
                     width: "calc(100% - 3rem)"
                 }}>
                <PanelGroup direction="horizontal" className={"w-full h-full"}>

                    <Panel maxSize={100}
                           defaultSize={sidePanelWidth ? 100 - sidePanelWidth : 100}
                           order={1}
                           minSize={30}>
                        <DatakiChatSession
                            onAnalyticsEvent={onAnalyticsEvent}
                            session={usedSession}
                            initialPrompt={initialPrompt ?? undefined}
                            onDataSourcesChange={onDataSourcesChange}
                            onProjectIdChange={onProjectIdChange}
                            onMessagesChange={onMessagesChange}
                            className={"p-4"}
                            initialDataSourceSelectionOpen={initialDataSourceSelectionOpen}
                            onDataSourceSelectionOpenChange={(open) => {
                                const searchParams = new URLSearchParams(location.search);
                                if (open) searchParams.set("dataSource", "true");
                                else searchParams.delete("dataSource");
                                navigate({
                                    search: searchParams.toString()
                                }, { replace: true });
                            }}
                            dateRange={[null, null]}
                            paramFilters={[]}
                            filters={[]}/>
                    </Panel>

                    {panelOpen === "chat_history" && <>
                        <PanelResizeHandle
                            className={cls("w-4 flex justify-center items-center bg-white hover:bg-surface-50 dark:bg-surface-950 dark:hover:bg-surface-800 border-l border-r", defaultBorderMixin, {
                                hidden: panelOpen !== "chat_history"
                            })}>
                            <DragHandleIcon size="small" color={"disabled"} className={"rotate-90"}/>
                        </PanelResizeHandle>
                        <Panel collapsible={true}
                               ref={panelRef}
                               onCollapse={() => {
                                   setPanelOpen(null);
                               }}
                               onResize={(size) => {
                                   if (panelOpen) {
                                       saveSidePanelWidth(panelOpen, size);
                                   }
                               }}
                               order={2}
                               minSize={10}
                               defaultSize={sidePanelWidth}>
                            {panelOpen === "chat_history" &&
                                <DashboardPanel title={<Typography variant={"label"} className={"flex-grow"}>
                                    Chat history
                                </Typography>}
                                                onClose={() => setPanelOpen(null)}
                                                className={"p-4"}>
                                    <ChatHistory
                                        onEntryClick={(session) => {
                                            navigate(navigation.homeUrl + "chat/" + session.id)
                                        }}
                                        onNewChatClick={() => navigate(getNewChatPath())}
                                    />
                                </DashboardPanel>
                            }
                        </Panel>
                    </>}

                </PanelGroup>
            </div>
            <div
                className={cls("w-12 h-full flex flex-col gap-2 p-1 border-l bg-surface-50 dark:bg-surface-950", defaultBorderMixin)}>
                <Tooltip title={"Chats history"}>
                    <IconButton
                        toggled={panelOpen === "chat_history"}
                        onClick={() => {
                            if (panelOpen === "chat_history") {
                                setPanelOpen(null);
                            } else {
                                setPanelOpen("chat_history");
                            }
                        }}>
                        <HistoryIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>

    )
}
