import React from "react";
import {
    ChatMessage,
    ChatSession,
    DashboardFilterConfig, DashboardPage,
    DashboardWidgetConfig,
    DataSource,
    DateParams,
    ParamFilter
} from "../../types";
import { Sheet } from "@firecms/ui";
import { DatakiChatSession } from "../chat/DatakiChatSession";
import { useFiltersStateView } from "../../hooks/useFiltersStateView";

export function WidgetChatSession({
                                      dryConfig: dryConfigProp,
                                      open,
                                      setOpen,
                                      params,
                                      initialParamFilters,
                                      filters: filtersProp,
                                      dashboardId,
                                      dashboardPageId,
                                      dashboardPage
                                  }: {
    dryConfig: DashboardWidgetConfig
    open: boolean,
    setOpen: (open: boolean) => void,
    params: DateParams,
    initialParamFilters: ParamFilter[],
    filters: DashboardFilterConfig[],
    dashboardId: string,
    dashboardPageId: string,
    dashboardPage?: DashboardPage
}) {

    const {
        dateRange,
        paramFilters,
        filters,
        view: dateRangeView,
    } = useFiltersStateView({
        initialDateRange: params ? [params.dateStart ?? null, params.dateEnd ?? null] : undefined,
        initialParamFilters,
        filters: filtersProp,
    });

    const [session, setSession] = React.useState<ChatSession>({
        id: "temp_session",
        created_at: new Date(),
        updated_at: new Date(),
        messages: [],
        dataSources: [],
        dashboardId: null,
    });

    const onMessagesChange = (messages: ChatMessage[]) => {
        const newSession = {
            ...session,
            messages
        };
        setSession(newSession);
    };

    const onDataSourcesChange = (dataSources: DataSource[]) => {
        const newSession = {
            ...session,
            dataSources
        };
        setSession(newSession);
    }

    const onProjectIdChange = (projectId: string) => {
        const newSession = {
            ...session,
            projectId
        };
        setSession(newSession);
    }

    return <Sheet
        side={"bottom"}
        open={open}
        onOpenChange={setOpen}
    >
        <DatakiChatSession
            className={"h-[92vh] p-4"}
            session={session}
            dateRange={dateRange}
            paramFilters={paramFilters}
            filters={filters}
            initialWidgetConfig={dryConfigProp}
            onMessagesChange={onMessagesChange}
            onDataSourcesChange={onDataSourcesChange}
            onProjectIdChange={onProjectIdChange}
            barChildren={dateRangeView}
            dashboardId={dashboardId}
            dashboardPageId={dashboardPageId}
            dashboardPage={dashboardPage}
        />
    </Sheet>
}
