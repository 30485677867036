import { useModeController } from "@firecms/core";

import * as monaco from "monaco-editor";
import { editor } from "monaco-editor";

import { useEffect, useRef, useState } from "react";
import { Parser } from "node-sql-parser";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import { cls } from "@firecms/ui";

const parser = new Parser();

monaco.editor.defineTheme("vs-dark-custom", {
    base: "vs-dark",
    inherit: true,
    rules: [],
    colors: {
        "editor.background": "#18181c"
    }
});

export type CodeEditorProps = {
    value?: string;
    autoHeight?: boolean;
    onChange?: (value?: string) => void;
    onMount?: (editor: any) => void;
    maxWidth?: number;
    loading?: boolean;
    defaultLanguage: string;
    sqlDialect?: string;
    onTextSelection?: (text: string) => void;
};

export function CodeEditor({
                               value,
                               autoHeight,
                               onChange,
                               maxWidth,
                               loading,
                               defaultLanguage,
                               sqlDialect,
                               onTextSelection,
                               ...props
                           }: CodeEditorProps) {
    const editorRef = useRef<IStandaloneCodeEditor>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const initialised = useRef(false);
    const [height, setHeight] = useState("100%");

    useEffect(() => {
        if (containerRef.current && !initialised.current) {
            initialised.current = true;
            editorRef.current = monaco.editor.create(containerRef.current, {
                value,
                theme: mode === "dark" ? "vs-dark-custom" : "light",
                language: defaultLanguage,
                scrollBeyondLastLine: false,
                minimap: { enabled: false },
                readOnly: loading,
                wordWrap: "on",
                wrappingStrategy: "advanced",
                overviewRulerLanes: 0,
                scrollbar: {
                    vertical: "hidden",
                    alwaysConsumeMouseWheel: false
                }
            });
            editorRef.current.onDidContentSizeChange(updateSize);
            editorRef.current?.getModel()?.onDidChangeContent((event) => {
                onChangeInternal(editorRef.current?.getValue());
            });
            updateSize();
        }
    }, []);

    // add size observer to container
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            updateSize();
        });
        resizeObserver.observe(containerRef.current!);
        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef.current]);

    function onChangeInternal(value?: string) {
        onChange?.(value);
        updateSize();
        if (value && defaultLanguage === "sql") {
            parseAndValidateSQL(value);
        }
    }

    const { mode } = useModeController();

    const updateSize = () => {
        const contentWidth = editorRef.current?.getContentWidth();
        const contentHeight = editorRef.current?.getContentHeight();
        if (!contentWidth || !contentHeight) {
            return;
        }
        // measure container width
        const containerWidth = containerRef.current?.clientWidth;
        const containerHeight = containerRef.current?.clientHeight;

        editorRef.current?.layout({
            width: containerWidth!,
            height: autoHeight ? contentHeight : containerHeight!
        });
        setHeight(contentHeight + "px");
    };

    const parseAndValidateSQL = (sql: string) => {
        if (!sqlDialect) {
            throw new Error("CodeEditor: sqlDialect is required when language is sql");
        }
        const markers = [];

        try {
            parser.astify(sql, {
                database: sqlDialect
            });
        } catch (e: any) {
            const { location } = e;
            if (location) {
                markers.push({
                    startLineNumber: location.start.line,
                    startColumn: location.start.column,
                    endLineNumber: location.end.line,
                    endColumn: location.end.column,
                    message: e.message,
                    severity: 8
                });
            }
        }
        const model = editorRef.current?.getModel();
        if (model)
            monaco.editor.setModelMarkers(model, "sql", markers);
    };

    return <div
        ref={containerRef}
        style={{
            height: autoHeight ? height : "100%",
            width: "100%"
        }}
        className={cls("rounded-lg flex-1 border border-surface-100 dark:border-surface-800 dark:border-opacity-80", {
            "overflow-hidden": autoHeight,
            "overflow-auto h-full": !autoHeight
        })}
        // defaultLanguage={defaultLanguage}
        // value={value}
        // onChange={onChangeInternal}
        // onMount={handleEditorDidMount}
        // {...props}
    />
}
