import React, { useEffect, useRef } from "react";

import Chart from "chart.js/auto"
import "chartjs-adapter-moment";
import { Colors } from "chart.js";
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel"

import { getRandomId, useModeController } from "@firecms/core";

import { HydratedChartConfig, WidgetSize } from "../../types";

Chart.register(Colors, FunnelController, TrapezoidElement);

export function ChartView({
                              config,
                              size,
                              ref
                          }: {
    ref?: React.RefObject<HTMLDivElement | null>,
    config: HydratedChartConfig,
    size: WidgetSize
}) {

    const modeController = useModeController();
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    const currentlyUsed = useRef(false);

    useEffect(() => {

        Chart.defaults.font.family = "'Rubik', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
        if (modeController.mode === "dark")
            Chart.defaults.color = "#ccc";
        else if (modeController.mode === "light")
            Chart.defaults.color = "#333";

        const current = canvasRef.current;
        if (!current) return;
        if (!config) {
            return;
        }
        if (currentlyUsed.current) {
            console.warn("Chart is already being used");
            return;
        }
        currentlyUsed.current = true;

        const chartConfig = {
            ...config,
            id: getRandomId(),
            options: {
                interaction: {
                    mode: "index",
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    ...config?.options?.plugins
                },
                ...config?.options
            }
        };

        const trimmedDataLabels = chartConfig.data.labels?.map((label: string | number) => {
            const labelValue = (typeof label === "number" ? label.toString() : label) ?? "";
            if (labelValue.length <= 30) {
                return labelValue;
            }
            return labelValue.substring(0, 30) + "...";
        });
        chartConfig.data.labels = trimmedDataLabels;

        const chart = new Chart(
            current,
            chartConfig as any
        );
        return () => {
            currentlyUsed.current = false;
            chart.destroy();
        };
    }, [config, modeController.mode]);

    return <div ref={ref} className={"relative flex-grow p-4 bg-white dark:bg-surface-950"}>
        <canvas className={"absolute"}
                style={{
                    top: 16,
                    width: size.width
                }}
                ref={canvasRef}/>
    </div>;
}
