import React, { useState } from "react";
import { getInitialDateRange } from "../utils/dates";
import { DatePickerWithRange } from "../components/DateRange";
import { DashboardFilterConfig, DataSource, ParamFilter } from "../types";
import { Button, FilterAltIcon, Popover, Typography } from "@firecms/ui";
import { FilterView } from "../components/FilterView";

import { paramHasValue } from "../utils/widgets";

interface UseFiltersStateViewParams {
    initialDateRange?: [Date | null, Date | null];
    initialParamFilters?: ParamFilter[];
    filters?: DashboardFilterConfig[];
    dataSources?: DataSource[];
    onFilterUpdate?: (updatedFilter: DashboardFilterConfig) => void;
    onFilterRemove?: (removedFilter: DashboardFilterConfig) => void;
}

export function useFiltersStateView({
                                        initialDateRange,
                                        initialParamFilters = [],
                                        filters = [],
                                        dataSources,
                                        onFilterUpdate,
                                        onFilterRemove
                                    }: UseFiltersStateViewParams) {

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(initialDateRange ?? getInitialDateRange());
    const [paramFilters, setParamFilters] = useState<ParamFilter[]>(initialParamFilters);

    const paramsWithValue = paramFilters.filter(paramHasValue);

    const [popupOpen, setPopupOpen] = useState(false);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setPopupOpen(false);
                event.stopPropagation();
                event.preventDefault();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const hasFilters = filters.length > 0;
    const hasFilterValues = paramsWithValue.length > 0;

    const view = <>
        <div className={"flex gap-2 items-center"}>

            <Popover
                side={"bottom"}
                open={popupOpen}
                onOpenChange={setPopupOpen}
                align={"end"}
                modal={true}
                className={"rounded-xl"}
                trigger={
                    <Button
                        variant={hasFilterValues ? "outlined" : "filled"}
                        color={hasFilterValues ? "primary" : "neutral"}
                        className={"rounded-xl"}>
                        <FilterAltIcon size={"small"}/>
                        {hasFilterValues ? `${paramsWithValue.length} filters` : "Filters"}
                    </Button>
                }>
                <div
                    className={"rounded-xl min-w-96 max-w-[600px] p-4 flex flex-col gap-2 text-text-primary dark:text-text-primary-dark"}>
                    <div className={"flex flex-col gap-4"}>
                        <Typography variant={"subtitle2"}>
                            Filters
                        </Typography>

                        {filters?.length === 0 && <Typography variant={"body2"}>
                            Create filters in natural language in the chat UI
                        </Typography>}

                        {filters.map((filter, index) => {
                            const value = paramFilters.find(f => f.key === filter.key)?.value;
                            const operator = paramFilters.find(f => f.key === filter.key)?.operator;
                            const usedDataSources = ("dataSources" in filter ? filter.dataSources : undefined) ?? dataSources;
                            if (!usedDataSources) {
                                throw new Error("FilterView INTERNAL: No data sources found");
                            }
                            return <FilterView
                                key={filter.key}
                                value={value}
                                operator={operator}
                                onSettingsOpen={(open) => {
                                    // setPopupOpen(!open);
                                }}
                                onChange={(newValue, newOperator) => {
                                    const newFilters = paramFilters.map(f =>
                                        f.key === filter.key
                                            ? {
                                                ...f,
                                                value: newValue,
                                                operator: newOperator
                                            }
                                            : f
                                    );

                                    if (!newFilters.some(f => f.key === filter.key)) {
                                        newFilters.push({
                                            key: filter.key,
                                            value: newValue,
                                            operator: newOperator,
                                            type: filter.type,
                                        });
                                    }

                                    setParamFilters(newFilters);
                                }}
                                onFilterRemove={onFilterRemove}
                                onFilterUpdate={onFilterUpdate}
                                filter={filter}
                                dataSources={usedDataSources}/>
                        })}
                    </div>
                </div>
            </Popover>
            <DatePickerWithRange
                className={"relative max-w-full bg-opacity-50 bg-surface-accent-200 dark:bg-surface-800 dark:bg-opacity-60 hover:bg-opacity-70 dark:hover:bg-surface-700 dark:hover:bg-opacity-40 h-[32px]  rounded-xl text-sm w-64"}
                dateRange={dateRange}
                setDateRange={setDateRange}/>
        </div>

    </>;

    return {
        dateRange,
        paramFilters,
        setParamFilters,
        filters,
        view
    }
}
