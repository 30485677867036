import { Button, cls, Sheet } from "@firecms/ui";
import { useChatSession } from "./chat/DatakiChatSession";
import { useState } from "react";
import { SQLQueryView } from "./SQLQueryView";

export function SQLPreview({
                               sqlQuery,
                               maxWidth,
                               onSaved,
                               className
                           }: {
    sqlQuery: string;
    maxWidth?: number;
    onSaved?: (sql?: string) => Promise<void>,
    className?: string;
}) {

    const chatSession = useChatSession();

    const [editorOpen, setEditorOpen] = useState(false);

    return (<>
            <div className={cls("relative flex flex-row gap-4", className)} style={{ maxWidth }}>
                <pre
                    className={"text-xs max-w-full  text-surface-700 dark:text-surface-200 mb-1 flex-grow text-wrap"}
                    dangerouslySetInnerHTML={{ __html: sqlQuery }}/>
                <Button
                    className={"absolute right-1"}
                    variant={"text"} onClick={() => setEditorOpen(true)}>
                    Run
                </Button>
            </div>

            <Sheet
                open={editorOpen}
                onOpenChange={setEditorOpen}
                side={"bottom"}>
                <div className={"h-[92vh]"}>
                    {editorOpen && <SQLQueryView
                        initialSql={sqlQuery}
                        params={chatSession.dateParams}
                        paramFilters={chatSession.paramFilters}
                        filters={chatSession.filters}
                        initialDataSources={chatSession.dataSources}
                        onSaved={onSaved}
                    />}
                </div>

            </Sheet>
        </>
    )
}
