import React, { useEffect } from "react";
import {
    DashboardFilterConfig,
    DateParams,
    DryChartWidgetConfig,
    DryTableWidgetConfig,
    DryWidgetConfig,
    ParamFilter,
    TableColumn
} from "../../types";
import { ErrorBoundary, slugify, useSnackbarController } from "@firecms/core";
import {
    AddIcon,
    Button,
    CircularProgress,
    cls,
    DownloadIcon,
    FilterAltIcon,
    IconButton,
    RefreshIcon,
    RemoveIcon,
    SettingsIcon,
    Tooltip,
    Typography
} from "@firecms/ui";
import { ConfigViewDialog } from "./ConfigViewDialog";
import { format } from "sql-formatter";
import { SQLTableView, useSQLTableConfig } from "../SQLTableView";
import equal from "react-fast-compare";
import { getDialectFromDataSources } from "../../utils/sql";
import { useDataki } from "../../DatakiProvider";
import { makeSQLQuery } from "../../api";
import { downloadDataAsCsv } from "../../utils/data_export";
import { getUsedParamsForConfig, isConfigRelatedToParam } from "../../utils/widgets";

function shouldRunHydration(config: DryTableWidgetConfig, a: LoadedConfig, b: LoadedConfig) {
    if (a.sql !== b.sql) return true;
    if (!equal(a.params, b.params)) return true;
    //compare column but ignore width
    if (!equal(a.columns?.map(c => ({
        ...c,
        width: undefined
    })), b.columns?.map(c => ({
        ...c,
        width: undefined
    })))) return true;
    if (!equal(a.paramFilters, b.paramFilters)) {
        return (b.paramFilters ?? []).some(p => isConfigRelatedToParam(config, p));
    }
    return false;
}

type LoadedConfig = {
    sql: string,
    columns?: TableColumn[],
    params?: DateParams,
    paramFilters?: ParamFilter[]
};

export function DryTableConfigView({
                                       dryConfig,
                                       params,
                                       paramFilters,
                                       filters,
                                       onUpdated,
                                       onRemoveClick,
                                       maxWidth,
                                       selected,
                                       actions,
                                       className,
                                       readOnly
                                   }: {
    dryConfig: DryTableWidgetConfig,
    params: DateParams,
    paramFilters: ParamFilter[],
    filters: DashboardFilterConfig[],
    onUpdated?: (newConfig: DryTableWidgetConfig) => void,
    onRemoveClick?: () => void,
    maxWidth?: number,
    selected?: boolean,
    actions?: React.ReactNode,
    className?: string,
    readOnly?: boolean
}) {

    const {
        apiEndpoint,
        getAuthToken
    } = useDataki();

    const dialect = getDialectFromDataSources(dryConfig.dataSources);

    const snackbar = useSnackbarController();

    const [configDialogOpen, setConfigDialogOpen] = React.useState(false);

    const [loadingDownload, setLoadingDownload] = React.useState(false);

    const columns = dryConfig?.table?.columns;
    const sql = dryConfig?.sql;
    const dataSources = dryConfig.dataSources;

    const sqlTableConfig = useSQLTableConfig({
        dataSources,
        sql,
        params,
        paramFilters,
        columns
    });

    const downloadFile = async () => {
        setLoadingDownload(true);
        try {
            const firebaseToken = await getAuthToken();
            const data = await makeSQLQuery({
                firebaseAccessToken: firebaseToken,
                dataSources,
                apiEndpoint,
                sql,
                params,
            });
            downloadDataAsCsv(data, slugify(dryConfig.title) + ".csv");
        } catch (e) {
            console.error("Error downloading data", e);
            snackbar.open({
                message: "Error downloading data",
                type: "error"
            });
        }
        setLoadingDownload(false);
    }

    const {
        viewRef,
        setData,
        dataLoading,
        dataLoadingError,
        refreshData,
    } = sqlTableConfig;

    const onConfigUpdated = (newConfig: DryTableWidgetConfig | DryChartWidgetConfig) => {
        if (!newConfig) return;
        console.log("Config updated", newConfig);
        onUpdated?.(newConfig as DryTableWidgetConfig);
        refreshData(newConfig.sql);
    };

    const onColumnResize = (params: { key: string, width: number }) => {
        const newColumns = columns?.map(col => {
            if (col.key === params.key) {
                return {
                    ...col,
                    width: params.width
                };
            }
            return col;
        });
        const newConfig: DryWidgetConfig = {
            ...dryConfig,
            table: {
                ...dryConfig.table,
                columns: newColumns ?? []
            }
        };
        onUpdated?.(newConfig);
    }

    const loadedConfig = React.useRef<LoadedConfig | null>(null);

    useEffect(() => {
        const currentConfig = {
            sql,
            columns,
            params,
            paramFilters
        };
        if (loadedConfig.current && !shouldRunHydration(dryConfig, loadedConfig.current, currentConfig)) {
            return;
        }

        if (sql) {
            setData([]);
            loadedConfig.current = currentConfig;
            try {
                const formattedDrySQL = format(dryConfig.sql, { language: dialect })
                const result = {
                    ...dryConfig,
                    sql: formattedDrySQL
                };
                if (!equal(result, dryConfig))
                    onUpdated?.(result);
            } catch (e) {
                console.error("Error formatting SQL", e);
            }
            refreshData();
        }
    }, [sql, columns, params, paramFilters, dryConfig]);

    const usedParams = getUsedParamsForConfig(dryConfig, paramFilters);

    return <>

        <div
            style={{ width: maxWidth }}
            className={cls("group flex flex-col w-full h-full bg-surface-50 dark:bg-surface-900 border border-surface-100 dark:border-surface-800 dark:border-opacity-80 rounded-lg overflow-hidden",
                selected ? "ring-offset-transparent ring-2 ring-primary ring-opacity-75 ring-offset-2" : "",
                className)}>

            <div
                className={"min-h-[54px] items-center flex flex-row w-full border-b border-surface-100 dark:border-surface-800 dark:border-opacity-80"}>
                <div className={"grow px-3 py-4 flex flex-row items-center gap-2 h-10"}>
                    {(usedParams ?? []).length > 0 &&
                        <Tooltip title={"This view is filtered by " + usedParams.map(p => p.key).join(", ")}>
                            <FilterAltIcon size={"smallest"} color={"disabled"}/>
                        </Tooltip>}
                    <Typography variant={"label"}
                                className={" line-clamp-1 "}>{dryConfig.title}</Typography>
                </div>

                {dataLoading && <div className={"m-3"}><CircularProgress size={"small"}/></div>}

                <div className={"m-2.5 flex-row gap-1 hidden group-hover:flex"}>
                    <Tooltip title={"Download"}>
                        <IconButton size={"small"}
                                    disabled={loadingDownload}
                                    onClick={downloadFile}>
                            {loadingDownload
                                ? <CircularProgress size={"small"}/>
                                : <DownloadIcon size={"small"}/>}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh data"}>
                        <IconButton size={"small"} onClick={() => {
                            setData([]);
                            refreshData();
                        }}>
                            <RefreshIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>
                    {!readOnly && onRemoveClick && <Tooltip title={"Remove this view"}>
                        <IconButton size={"small"} onClick={onRemoveClick}>
                            <RemoveIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>}

                    {!readOnly && onUpdated && <Tooltip title={"Edit widget configuration"}>
                        <IconButton size={"small"} onClick={() => setConfigDialogOpen(true)}>
                            <SettingsIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>}

                    {!readOnly && actions}

                </div>
            </div>

            {dryConfig?.table && (<SQLTableView sqlTableConfig={sqlTableConfig}
                                                onColumnResize={onColumnResize}/>)}

            <ErrorBoundary>
                {dryConfig && <ConfigViewDialog open={configDialogOpen}
                                                setOpen={setConfigDialogOpen}
                                                dryConfig={dryConfig}
                                                params={params}
                                                paramFilters={paramFilters}
                                                filters={filters}
                                                onUpdate={onConfigUpdated}
                />}
            </ErrorBoundary>
        </div>

    </>;
}
