import React, { useEffect, useState } from "react";
import JSON5 from "json5";
import { Button, Paper, Tooltip } from "@firecms/ui";
import { DashboardPage, DashboardWidgetConfig, DryWidgetConfig, SQLDialect } from "../../types";
import { DEFAULT_WIDGET_SIZE } from "../../utils/widgets";
import { DryChartConfigView } from "../widgets/DryChartConfigView";
import { CircularProgressCenter, ErrorBoundary } from "@firecms/core";
import { DryTableConfigView } from "../widgets/DryTableConfigView";
import { useChatSession } from "./DatakiChatSession";
import { format } from "sql-formatter";
import { FilterSuggestionView } from "../FilterSuggestionView";
import { useDataki } from "../../DatakiProvider";

export function WidgetChatMessageView({
                                          rawDryConfig,
                                          maxWidth,
                                          loading,
                                          onWidgetModified,
                                          dialect,
                                          dashboardPage,
                                          dashboardId,
                                          dashboardPageId,
                                          onDashboardWidgetUpdated
                                      }: {
    rawDryConfig?: object,
    loading?: boolean,
    maxWidth?: number,
    onWidgetModified?: (rawDryConfig: string, config: object) => void,
    dialect: SQLDialect,
    dashboardPage?: DashboardPage,
    dashboardId?: string,
    dashboardPageId?: string,
    onDashboardWidgetUpdated?: (config: DashboardWidgetConfig) => void,
}) {

    const {
        dataSources,
        dateParams,
        paramFilters,
        filters,
        initialWidgetConfig
    } = useChatSession();

    const dataki = useDataki();

    const [dryConfig, setDryConfig] = useState<DryWidgetConfig | null>(null);
    const [parsingError, setParsingError] = useState<Error | null>(null);

    useEffect(() => {
        if (rawDryConfig && !loading) {
            try {
                setParsingError(null);
                if (Array.isArray(rawDryConfig)) {
                    throw new Error("Invalid config, expected an object");
                }
                const newDryConfig = rawDryConfig as DryWidgetConfig;
                if (newDryConfig.type === "chart" || newDryConfig.type === "table") {
                    if (!newDryConfig.dataSources && dataSources) {
                        try {
                            newDryConfig.sql = format(newDryConfig.sql, { language: dialect });
                        } catch (e) {
                            console.error("Error formatting SQL", e);
                        }
                        newDryConfig.dataSources = dataSources;
                        onChange(newDryConfig);
                    }
                }
                setDryConfig(newDryConfig);
            } catch (e: any) {
                console.error(rawDryConfig);
                console.error("Error parsing dry config", e);
                setParsingError(e);
            }
        }
    }, [loading, rawDryConfig]);

    function onChange(newConfig: object) {
        onWidgetModified?.(JSON5.stringify(newConfig, null, 2), newConfig);
    }

    if (dryConfig?.type === "filter_suggestion") {
        if (!dryConfig.filter) {
            throw new Error("Invalid filter config, expected a filter object");
        }
        if (!dashboardPage || !dashboardId || !dashboardPageId) {
            throw new Error("WidgetChatMessageView INTERNAL: No dashboard page found");
        }
        return <ErrorBoundary>
            <FilterSuggestionView suggestion={dryConfig}
                                  dashboardPage={dashboardPage}
                                  maxWidth={maxWidth}
                                  dataSources={dataSources}
                                  dashboardId={dashboardId}
                                  dashboardPageId={dashboardPageId}
                                  params={dateParams}
                                  paramFilters={paramFilters}
                                  onSuggestionUpdate={(suggestion) => {
                                      setDryConfig(suggestion);
                                      onChange(suggestion);
                                  }}/>
        </ErrorBoundary>
    }

    if (dryConfig?.type === "dashboard_delta_update") {
        return <code className={"block my-4"}>{JSON.stringify(dryConfig, null, 2)}</code>
    }

    const widgetHeight = dryConfig?.size?.height ?? DEFAULT_WIDGET_SIZE.height;
    const widgetMaxWidth = Math.min(maxWidth ?? Infinity, (dryConfig?.type === "table" ? undefined : dryConfig?.size?.width ?? DEFAULT_WIDGET_SIZE.width) ?? Infinity);
    const existingWidget = dashboardPage?.widgets.find(w => w.id === dryConfig?.id);

    const actions = dashboardId && dashboardPageId && existingWidget && dryConfig
        ? <Button variant={"outlined"}
                  size={"small"}
                  onClick={() => {
                      console.log("Updating widget", dryConfig);
                      const result = {
                          ...existingWidget,
                          ...dryConfig
                      } satisfies DashboardWidgetConfig;
                      dataki.onWidgetUpdate(dashboardId, dashboardPageId, existingWidget.id, result);
                      onDashboardWidgetUpdated?.(result);
                  }}>
            Update widget
        </Button>
        : (dryConfig
            ? <Tooltip title={"Add this view to a dashboard"}>
                <Button variant={"outlined"}
                        size={"small"}
                        onClick={() => {
                            if (!dashboardId || !dashboardPageId) {
                                throw new Error("WidgetChatMessageView INTERNAL: No dashboard page found");
                            }
                            const dashboardWidget = dataki.addDashboardWidget(dashboardId, dryConfig);

                            return onDashboardWidgetUpdated?.(dashboardWidget);
                        }}>
                    Add to dashboard
                </Button>
            </Tooltip>
            : null);

    return (
        <div className={"flex flex-col gap-2 mb-4"}
             style={{
                 width: widgetMaxWidth,
                 height: widgetHeight + 92
             }}>

            {loading && (
                <Paper className="p-4"
                       style={{
                           height: widgetHeight + 92,
                           width: widgetMaxWidth
                       }}>
                    <CircularProgressCenter/>
                </Paper>
            )}

            {!loading && dryConfig && (
                <ErrorBoundary>
                    {dryConfig.type === "chart" && <DryChartConfigView
                        dryConfig={dryConfig}
                        params={dateParams}
                        paramFilters={paramFilters}
                        filters={filters}
                        actions={actions}
                        onUpdated={(newConfig) => {
                            setDryConfig(newConfig);
                            onChange(newConfig);
                        }}
                        maxWidth={maxWidth}
                    />}
                    {dryConfig.type === "table" && <DryTableConfigView
                        dryConfig={dryConfig}
                        params={dateParams}
                        paramFilters={paramFilters}
                        filters={filters}
                        actions={actions}
                        onUpdated={(newConfig) => {
                            setDryConfig(newConfig);
                            onChange(newConfig);
                        }}
                        maxWidth={maxWidth}
                    />}
                </ErrorBoundary>)}

            {parsingError && (
                <div className={"text-red-500"}>
                    {parsingError.message}
                </div>
            )}
        </div>
    );
}
