import { DashboardWidgetConfig, DryChartWidgetConfig, DryTableWidgetConfig, ParamFilter } from "../types";

export const DEFAULT_WIDGET_SIZE = {
    height: 340,
    width: 600
};

export const DEFAULT_PAPER_SIZE = {
    height: 1525,
    width: 1275
};

export const TITLE_HEIGHT = 64;
export const SUBTITLE_HEIGHT = 48;
export const TEXT_WIDTH = 600;

export const DEFAULT_GRID_SIZE = 25;


export function getConfigWithoutSize<T extends DryChartWidgetConfig | DryTableWidgetConfig>(config: T | DashboardWidgetConfig): T {
    const {
        size,
        // @ts-ignore
        position,
        ...rest
    } = config;
    return rest as T;
}

export function isConfigRelatedToParam(config: DryChartWidgetConfig | DryTableWidgetConfig, param: ParamFilter) {
    return config.sql.includes("@" + param.key);
}

export function getUsedParamsForConfig(config: DryChartWidgetConfig | DryTableWidgetConfig, params: ParamFilter[]) {
    return params
        .filter(paramHasValue)
        .filter(param => isConfigRelatedToParam(config, param));
}

export function paramHasValue(param: ParamFilter) {
    return Boolean(param.value) && (Array.isArray(param.value) ? param.value.length > 0 : true);
}

