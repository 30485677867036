import React from "react";
import { DashboardsOverview } from "./home/DashboardsOverview";
import { Alert, ForumIcon, LoadingButton, Typography } from "@firecms/ui";
import { resetAllTooltips } from "../hooks/useOnboardingTooltip";
import { OnboardingTooltip } from "./OnboardingTooltip";
import { useNewDashboardFlow } from "./hooks/useNewDashboardFlow";

// import { TeamsSelect } from "./TeamsSelect";

export function HomePage() {

    const {
        dialog,
        openDialog,
        loading
    } = useNewDashboardFlow();

    return (
        <div className={"flex flex-col container mx-auto px-4 md:px-6  my-4 pt-8 flex-1"}>
            <div className={"my-8 flex flex-col gap-4"}>

                {/*<TeamsSelect/>*/}

                {/*<Button onClick={resetAllTooltips} variant={"text"}>*/}
                {/*    Reset onboarding*/}
                {/*</Button>*/}
                <Typography variant={"h3"}
                            gutterBottom={true}
                            className={"font-mono my-2"}
                            onClick={resetAllTooltips}>
                    Welcome to DATAKI
                </Typography>
                <Typography>
                    Dataki is a conversational interface to your data.
                </Typography>
                <Typography>
                    You can ask questions and <b>explore your data</b> in a natural way.
                    Then you can <b>save your charts and tables</b> in dashboards to share them with your team.
                </Typography>

                <Alert>
                    You can use demo data from the <code>bigquery-public-data.thelook_ecommerce</code> dataset, or use
                    your
                    own
                </Alert>
                <OnboardingTooltip id={"home_chat"}
                                   className={"w-fit"}
                                   side={"right"}
                                   title={"Start generating your views here!"}>
                    <LoadingButton
                        size={"xl"}
                        loading={loading}
                        className={"my-4 gap-4 rounded-2xl"}
                        onClick={async () => {
                            openDialog();
                        }}>
                        <ForumIcon/>
                        Create new dashboard
                    </LoadingButton>
                </OnboardingTooltip>

                {/*<Button*/}
                {/*    size={"xl"}*/}
                {/*    className={"my-4 gap-4 rounded-2xl"}*/}
                {/*    onClick={async () => {*/}
                {/*        const sessionId = await datakiConfig.createChatSessionId();*/}
                {/*        navigate(`/chat/${sessionId}`);*/}
                {/*    }}>*/}
                {/*    <ForumIcon/>*/}
                {/*    Start new chat*/}
                {/*</Button>*/}

            </div>


            {/*<Separator orientation={"horizontal"}/>*/}

            <DashboardsOverview/>

            {dialog}
        </div>
    )
}
