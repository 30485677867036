import React, { useEffect, useState } from "react";
import equal from "react-fast-compare"

import { MarkdownElement, parseMarkdown } from "../../utils/parser";
import {
    Button,
    CheckIcon,
    CloseIcon,
    ContentCopyIcon,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Label,
    LoopIcon,
    Skeleton,
    TextField,
    ThumbDownOffAltIcon,
    Tooltip,
    Typography
} from "@firecms/ui";
import {
    DashboardPage, DashboardWidgetConfig,
    DryChartWidgetConfig,
    DryTableWidgetConfig,
    DryWidgetConfig,
    FeedbackSlug,
    SQLDialect
} from "../../types";
import { WidgetChatMessageView } from "./WidgetChatMessageView";

export function SystemMessage({
                                  text,
                                  loading,
                                  maxWidth,
                                  onRegenerate,
                                  canRegenerate,
                                  onFeedback,
                                  onUpdatedMessage,
                                  dialect,
                                  dashboardPage,
                                  dashboardId,
                                  dashboardPageId,
                                  onDashboardWidgetUpdated
                              }: {
    text?: string,
    loading?: boolean,
    maxWidth?: number,
    onRegenerate?: () => void,
    canRegenerate?: boolean,
    onFeedback?: (reason?: FeedbackSlug, feedbackMessage?: string) => void,
    onUpdatedMessage?: (message: string) => void,
    dialect: SQLDialect,
    dashboardPage?: DashboardPage,
    dashboardId?: string,
    dashboardPageId?: string,
    onDashboardWidgetUpdated?: (config: DashboardWidgetConfig) => void
}) {

    const [parsedElements, setParsedElements] = useState<MarkdownElement[] | null>();

    useEffect(() => {
        if (text) {
            const markdownElements = parseMarkdown(text);
            console.log("Parsed markdown", text);
            console.log("Parsed elements", markdownElements);
            setParsedElements(markdownElements);
        }
    }, [text]);

    const onUpdatedElements = (elements: MarkdownElement[]) => {
        const markdown = elements.map((element) => {
            if (element.type === "html") {
                return element.content;
            } else if (element.type === "widget") {
                return "```json\n" + element.content + "\n```";
            }
            throw new Error("Unknown element type");
        }).join("\n");
        onUpdatedMessage?.(markdown);
    }

    return <>

        {parsedElements && parsedElements.flatMap((element, index) => {

            const onWidgetModified = (updatedContent: string, data: object) => {
                if (equal(updatedContent, parsedElements[index].content)) {
                    return;
                }
                const updatedElements = [...parsedElements];
                updatedElements[index] = {
                    type: "widget",
                    content: updatedContent,
                    data
                } satisfies MarkdownElement;
                setParsedElements(updatedElements);
                onUpdatedElements(updatedElements);
            };

            if (element.type === "html") {
                return [
                    <div
                        style={{
                            maxWidth,
                        }}
                        className={"max-w-full prose dark:prose-invert prose-headings:font-title text-base text-surface-700 dark:text-surface-200 mb-3"}
                        dangerouslySetInnerHTML={{ __html: element.content }}
                        key={index}/>
                ];
            } else if (element.type === "widget") {
                if (!element.data) {
                    console.error("Widget element without data", element);
                    return null;
                }

                if (Array.isArray(element.data)) {
                    return element.data.map((entry, childIndex) => {

                        return <WidgetChatMessageView key={`${childIndex}-${index}`}
                                                      loading={loading}
                                                      rawDryConfig={entry}
                                                      maxWidth={maxWidth}
                                                      dialect={dialect}
                                                      dashboardPage={dashboardPage}
                                                      dashboardId={dashboardId}
                                                      dashboardPageId={dashboardPageId}
                                                      onWidgetModified={onWidgetModified}
                                                      onDashboardWidgetUpdated={onDashboardWidgetUpdated}
                        />
                    });
                }

                return [
                    <WidgetChatMessageView key={index}
                                           loading={loading}
                                           rawDryConfig={element.data}
                                           maxWidth={maxWidth}
                                           dialect={dialect}
                                           dashboardPage={dashboardPage}
                                           dashboardId={dashboardId}
                                           dashboardPageId={dashboardPageId}
                                           onWidgetModified={onWidgetModified}
                                           onDashboardWidgetUpdated={onDashboardWidgetUpdated}
                    />
                ];
            } else {
                console.error("Unknown element type", element);
                return null;
            }
        })}

        {loading && <Skeleton className={"max-w-4xl mt-1 mb-4"}/>}

        <div className={"mt-1 flex flex-row gap-1"}>
            {canRegenerate && <Tooltip title={"Regenerate"}>
                <IconButton size={"smallest"} disabled={loading} onClick={onRegenerate}>
                    <LoopIcon size={"smallest"}/>
                </IconButton>
            </Tooltip>}

            <Tooltip title={"Copy"}>
                <MessageCopyIcon text={text ?? ""} disabled={loading}/>
            </Tooltip>

            <BadMessageIcon disabled={loading}
                            onFeedback={onFeedback}/>
        </div>

    </>;
}

function MessageCopyIcon({
                             text,
                             disabled
                         }: {
    text: string,
    disabled?: boolean
}) {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [copied]);

    return <IconButton size={"smallest"}
                       disabled={disabled}
                       onClick={() => {
                           setCopied(true);
                           navigator.clipboard.writeText(text);
                       }}>
        {copied ? <CheckIcon size={"smallest"}/> : <ContentCopyIcon size={"smallest"}/>}
    </IconButton>;
}

function BadMessageIcon({
                            disabled,
                            onFeedback
                        }: {
    disabled?: boolean,
    onFeedback?: (reason?: FeedbackSlug, feedback?: string) => void,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selected, setSelected] = useState<FeedbackSlug | null>(null);
    const [feedbackText, setFeedbackText] = useState<string>("");
    return <>

        <Tooltip title={dialogOpen ? undefined : "Bad response"}>
            <IconButton size={"smallest"}
                        disabled={disabled}
                        onClick={() => {
                            setDialogOpen(true);
                        }}>
                <ThumbDownOffAltIcon size={"smallest"}/>
            </IconButton>
        </Tooltip>

        <Dialog
            maxWidth={"xl"}
            open={dialogOpen}
            onOpenChange={setDialogOpen}
            onOpenAutoFocus={(e) => {
                e.preventDefault();
            }}>
            <DialogContent className={"flex flex-col gap-4"}>
                <Typography variant={"label"}>
                    What was wrong with the response?
                </Typography>
                <div className={"flex flex-row gap-2 flex-wrap"}>
                    <FeedbackLabel title={"Not helpful"}
                                   value={"not_helpful"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                    <FeedbackLabel title={"Not factually correct"}
                                   value={"not_factually_correct"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                    <FeedbackLabel title={"Chart is incorrect"}
                                   value={"chart_is_incorrect"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                    <FeedbackLabel title={"Incorrect code"}
                                   value={"incorrect_code"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                    <FeedbackLabel title={"Unsafe or problematic"}
                                   value={"unsafe_or_problematic"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                    <FeedbackLabel title={"Other"}
                                   value={"other"}
                                   selected={selected}
                                   setSelected={setSelected}/>
                </div>
                <TextField size={"small"}
                           value={feedbackText}
                           onChange={(e) => setFeedbackText(e.target.value)}
                           placeholder={"Feel free to add specific details"}></TextField>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"}
                        onClick={() => {
                            setDialogOpen(false);
                            onFeedback?.(selected, feedbackText);
                        }}>Submit</Button>
            </DialogActions>

            <IconButton className={"absolute top-4 right-4"}
                        onClick={() => setDialogOpen(false)}>
                <CloseIcon/>
            </IconButton>
        </Dialog>
    </>;
}

function FeedbackLabel({
                           setSelected,
                           title,
                           value,
                           selected
                       }: {
    value: FeedbackSlug,
    title: string,
    selected: FeedbackSlug,
    setSelected: (value: FeedbackSlug | null) => void
}) {
    return <Label border={true}
                  className={value === selected ? "bg-surface-300 dark:bg-surface-700 hover:bg-surface-300 hover:dark:bg-surface-700" : ""}
                  onClick={() => {
                      setSelected(value);
                  }}>{title}</Label>;
}
