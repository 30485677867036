import React, { useContext, useEffect, useState } from "react";
import {
    collection,
    doc,
    getDoc,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    Timestamp,
    where
} from "@firebase/firestore";
import { FirebaseApp } from "@firebase/app";
import {
    ChatSession,
    Dashboard, DashboardFilterConfig,
    DashboardPage,
    DashboardUpdateType,
    DashboardWidgetConfig,
    DatakiUser,
    DryChartWidgetConfig,
    DryTableWidgetConfig,
    FilterConfig,
    Position,
    Team,
    TextItem,
    WidgetSize
} from "./types";
import { DEFAULT_GRID_SIZE, DEFAULT_WIDGET_SIZE } from "./utils/widgets";
import { randomString, User } from "@firecms/core";
import equal from "react-fast-compare"

export type DatakiConfig = {
    loading: boolean;
    apiEndpoint: string;
    getAuthToken: () => Promise<string>;

    teams: Team[];
    getTeam: (teamId: string) => Promise<Team>;
    createTeam: (team: Omit<Team, "id">) => Promise<Team>;
    saveTeam: (team: Team) => Promise<void>;

    getUser: (uid: string) => Promise<DatakiUser>;
    updateDashboardPermissions: (dashboardId: string, uid: string, permissions: "read" | "write") => Promise<void>;

    createChatSessionId: () => Promise<string>;
    saveChatSession: (session: ChatSession) => Promise<void>;
    getChatSession: (sessionId: string) => Promise<ChatSession | undefined>;
    listenChatSessions: (params: ListenChatSessionsParams) => () => void;
    dashboards: Dashboard[];
    createDashboard: (dashboard?: Partial<Dashboard>) => Promise<Dashboard>;
    saveDashboard: (dashboard: Dashboard, updateType?: DashboardUpdateType) => Promise<void>;
    updateDashboard: (id: string, dashboardData: Partial<Dashboard>, updateType?: DashboardUpdateType) => Promise<void>;
    deleteDashboard: (id: string) => Promise<void>;
    listenDashboard: (dashboardId: string, onDashboardUpdate: (dashboard: Dashboard | null) => void) => () => void;
    listenDashboardHistory: (dashboardId: string, onHistoryUpdate: (history: Dashboard[]) => void) => () => void;
    addDashboardText: (dashboardId: string, pageId: string, node: TextItem) => void;
    updateDashboardText: (dashboardId: string, pageId: string, id: string, node: TextItem) => void;
    addDashboardWidget: (dashboardId: string, widget: DryChartWidgetConfig | DryTableWidgetConfig) => DashboardWidgetConfig;
    onWidgetResize: (dashboardId: string, pageId: string, id: string, size: WidgetSize) => void;
    onWidgetUpdate: (dashboardId: string, pageId: string, id: string, widget: DashboardWidgetConfig) => void;
    onWidgetMove: (dashboardId: string, pageId: string, id: string, position: Position) => void;
    onWidgetRemove: (dashboardId: string, pageId: string, id: string) => void;
    onWidgetsRemove: (dashboardId: string, pageId: string, id: string[]) => void;
    revertDashboard: (dashboard: Dashboard) => Promise<void>;
    updateDashboardPage: (id: string, pageId: string, dashboard: Partial<DashboardPage>) => void;

    addFilterToDashboard: (dashboardId: string, pageId: string, filter: DashboardFilterConfig) => Promise<void>;

    firebaseApp?: FirebaseApp;

    appBarRef: React.RefObject<HTMLDivElement | null>;
};

export interface DatakiConfigParams {
    enabled?: boolean;
    firebaseApp?: FirebaseApp;
    dashboardsPath?: string;
    userSessionsPath?: string;
    teamsPath?: string;
    getAuthToken: () => Promise<string>;
    apiEndpoint: string;
    user: User | null,
    appBarRef: React.RefObject<HTMLDivElement | null>;
}

const DatakiConfigContext = React.createContext<DatakiConfig>({} as any);

export interface ListenChatSessionsParams {
    dashboardId?: string;
    onChatSessionsUpdate: (sessions: ChatSession[]) => void;
}

export function useBuildDatakiConfig({
                                         enabled = true,
                                         firebaseApp,
                                         userSessionsPath,
                                         dashboardsPath,
                                         teamsPath,
                                         getAuthToken,
                                         apiEndpoint,
                                         user,
                                         appBarRef
                                     }: DatakiConfigParams): DatakiConfig {

    const dashboardsRef = React.useRef<Dashboard[]>([]);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [dashboardsLoading, setDashboardsLoading] = useState<boolean>(true);

    const [teams, setTeams] = useState<Team[]>([]);

    function updateDashboards(newDashboards: Dashboard[]) {
        dashboardsRef.current = newDashboards;
        setDashboards(newDashboards);
    }

    async function createTeam(team: Omit<Team, "id">): Promise<Team> {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        if (!user) throw Error("User not found");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !teamsPath) throw Error("useBuildDatakiConfig Firestore not initialised");

        console.log("Creating team", team);
        const documentReference = doc(collection(firestore, teamsPath));
        const id = documentReference.id;
        const data = {
            ...team,
            users: [user.uid],
            created_at: new Date(),
            updated_at: new Date()
        };
        await setDoc(documentReference, data);
        return {
            ...data,
            id
        } as Team;
    }

    async function saveTeam(team: Team) {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !teamsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const {
            id,
            ...teamData
        } = team;
        const teamDoc = doc(firestore, teamsPath, id);
        const docSnapshot = await getDoc(teamDoc);
        if (!docSnapshot.exists()) {
            throw Error("Team not found");
        }
        return setDoc(teamDoc, {
            ...teamData,
            updated_at: new Date()
        });
    }

    async function getTeam(teamId: string) {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !teamsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const teamDoc = doc(firestore, teamsPath, teamId);
        const docSnapshot = await getDoc(teamDoc);
        if (!docSnapshot.exists()) {
            throw Error("Team not found");
        }
        return {
            id: docSnapshot.id,
            ...docSnapshot.data()
        } as Team;
    }

    const getUser = async (uid: string) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore) throw Error("useBuildDatakiConfig Firestore not initialised");
        const userDoc = doc(firestore, "users", uid);
        const docSnapshot = await getDoc(userDoc);
        if (!docSnapshot.exists()) {
            throw Error("User not found");
        }
        return {
            id: docSnapshot.id,
            ...docSnapshot.data()
        } as DatakiUser;
    }

    const updateDashboardPermissions = async (dashboardId: string, uid: string, permissions: "read" | "write") => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("updateDashboardPermissions: Dashboard not found");
        const updatedPermissions = dashboard.permissions?.map(p => {
            if (p.uid === uid) {
                return {
                    ...p,
                    type: permissions
                }
            }
            return p;
        }) ?? [];
        return saveDashboard({
            ...dashboard,
            permissions: updatedPermissions
        });
    }

    const createChatSessionId = async (): Promise<string> => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !userSessionsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        return doc(collection(firestore, userSessionsPath)).id;
    };

    const saveChatSession = async (session: ChatSession) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !userSessionsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const {
            id,
            ...sessionData
        } = session;
        const sessionDoc = doc(firestore, userSessionsPath, id);
        return setDoc(sessionDoc, {
            ...sessionData,
            updated_at: new Date()
        });
    };

    const getChatSession = async (sessionId: string) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !userSessionsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const sessionDoc = doc(firestore, userSessionsPath, sessionId);
        const docSnapshot = await getDoc(sessionDoc);
        if (!docSnapshot.exists()) {
            return undefined;
        }
        return {
            id: docSnapshot.id,
            ...docSnapshot.data()
        } as ChatSession;
    }

    const listenChatSessions = ({
                                    dashboardId,
                                    onChatSessionsUpdate
                                }: ListenChatSessionsParams) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !userSessionsPath) throw Error("useBuildDatakiConfig Firestore not initialised");

        const sessionsRef = query(
            collection(firestore, userSessionsPath),
            where("dashboardId", "==", dashboardId ?? null),
            orderBy("updated_at", "desc"),
        );
        return onSnapshot(sessionsRef.withConverter(timestampToDateConverter), {
            next: async (snapshot) => {
                const sessions = snapshot.docs.map(async doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    } as ChatSession;
                });
                onChatSessionsUpdate(await Promise.all(sessions));
            },
            error: (e) => {
                console.error(e);
            }
        });

    }

    const saveDashboard = async (dashBoard: Dashboard, updateType?: DashboardUpdateType) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const {
            id,
            ...dashboardData
        } = dashBoard;
        const dashboardDoc = doc(firestore, dashboardsPath, id);

        // update dashboards ref
        if (dashboardsRef.current.map(d => d.id).includes(id)) {
            dashboardsRef.current = dashboardsRef.current.map(d => d.id === id ? dashBoard : d);
            updateDashboards(dashboardsRef.current);
        } else {
            dashboardsRef.current = [dashBoard, ...dashboardsRef.current];
            updateDashboards(dashboardsRef.current);
        }
        const data = {
            ...dashboardData,
            updated_at: new Date(),
            updated_by: user?.uid,
            updated_type: updateType ?? null
        };
        console.log("Saving dashboard", dashboardDoc.id, data, updateType);
        return setDoc(dashboardDoc, data);
    };

    const listenDashboard = (id: string, onDashboardUpdate: (dashboard: Dashboard | null) => void) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        return onSnapshot(doc(firestore, dashboardsPath, id).withConverter(timestampToDateConverter), {
            next: (snapshot) => {
                if (!snapshot.exists()) {
                    onDashboardUpdate(null);
                    return;
                }
                const dashboard = {
                    id: snapshot.id,
                    ...snapshot.data()
                } as Dashboard;
                onDashboardUpdate(dashboard);
            },
            error: (e) => {
                console.error(e);
            }
        });
    };

    const listenDashboardHistory = (id: string, onHistoryUpdate: (history: Dashboard[]) => void) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        return onSnapshot(query(collection(firestore, dashboardsPath, id, "history"), orderBy("updated_at", "desc")).withConverter(timestampToDateConverter), {
            next: async (snapshot) => {
                const history = snapshot.docs.map(async doc => {
                    const updatedByUser = doc.data().updated_by ? await getUser(doc.data().updated_by) : null;
                    return {
                        id,
                        revision: doc.id,
                        ...doc.data(),
                        updatedByUser
                    } as Dashboard;
                });
                onHistoryUpdate(await Promise.all(history));
            },
            error: (e) => {
                console.error(e);
            }
        });

    }

    const createDashboard = async (dashboardData?: Partial<Dashboard>): Promise<Dashboard> => {
        if (user === null)
            throw Error("User not found");
        if (!firebaseApp)
            throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const documentReference = doc(collection(firestore, dashboardsPath));
        const id = documentReference.id;
        const data = initializeDashboard(user.uid, dashboardData);
        const newDashboard = { id, ...data };
        updateDashboards([newDashboard, ...dashboardsRef.current]);
        await setDoc(documentReference, data);
        return newDashboard;
    };

    const deleteDashboard = async (id: string) => {
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const dashboard = dashboardsRef.current.find(d => d.id === id);
        if (!dashboard) throw Error("deleteDashboard: Dashboard not found");
        dashboard.deleted = true;
        return saveDashboard(dashboard, "dashboard_delete");
    }

    const addDashboardText = (dashboardId: string, pageId: string, node: TextItem) => {
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardText: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardText: Page not found");
        page.widgets.push(node);
        return saveDashboard(dashboard, "text_update");
    };

    const updateDashboardText = (dashboardId: string, pageId: string, id: string, node: TextItem) => {
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("updateDashboardText: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("updateDashboardText: Page not found");
        const widgetIndex = page.widgets.findIndex(w => w.id === id);
        if (widgetIndex === -1) throw Error("updateDashboardText: Widget not found");
        page.widgets.splice(widgetIndex, 1, node);
        return saveDashboard(dashboard, "text_update");
    };

    const addDashboardWidget = (id: string, widget: DryChartWidgetConfig | DryTableWidgetConfig): DashboardWidgetConfig => {
        const dashboard = dashboardsRef.current.find(d => d.id === id);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const dashboardPage = dashboard.pages[0];
        const newWidget = convertWidgetToDashboardConfig(dashboardPage, widget);
        dashboardPage.widgets.push(newWidget);
        saveDashboard(dashboard, "widget_create").catch(console.error);
        return newWidget;
    };

    const onWidgetResize = (dashboardId: string, pageId: string, id: string, size: WidgetSize) => {
        console.log("onWidgetResize", dashboardId, pageId, id, size)
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const widget = page.widgets.find(w => w.id === id);
        if (!widget) throw Error("addDashboardWidget: Widget not found");
        widget.size = size;
        return saveDashboard(dashboard, "widget_resize");
    };

    const onWidgetMove = (dashboardId: string, pageId: string, id: string, position: Position) => {
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const widget = page.widgets.find(w => w.id === id);
        if (!widget) throw Error("addDashboardWidget: Widget not found");
        if (equal(widget.position, position)) return;
        console.log("onWidgetMove", dashboardId, pageId, id, position)
        widget.position = position;
        return saveDashboard(dashboard, "widget_move");
    };

    const onWidgetRemove = (dashboardId: string, pageId: string, id: string) => {
        console.log("onWidgetRemove", dashboardId, pageId, id)
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const widgetIndex = page.widgets.findIndex(w => w.id === id);
        if (widgetIndex === -1) throw Error("addDashboardWidget: Widget not found");
        page.widgets.splice(widgetIndex, 1);
        return saveDashboard(dashboard, "widget_remove");
    };

    const onWidgetsRemove = (dashboardId: string, pageId: string, ids: string[]) => {
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const widgets = page.widgets.filter(w => !ids.includes(w.id));
        page.widgets = widgets;
        return saveDashboard(dashboard, "widgets_remove");
    };

    const onWidgetUpdate = (dashboardId: string, pageId: string, id: string, widget: DashboardWidgetConfig) => {
        console.log("updateDashboardWidget", dashboardId, pageId, id, widget)
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const existingWidget = page.widgets.find(w => w.id === id);
        if (!existingWidget) throw Error("addDashboardWidget: Widget not found");
        const widgetIndex = page.widgets.findIndex(w => w.id === id);
        if (widgetIndex === -1) throw Error("addDashboardWidget: Widget not found");
        const currentWidget = page.widgets[widgetIndex];
        if (equal(currentWidget, widget)) return;
        page.widgets.splice(widgetIndex, 1, widget);
        return saveDashboard(dashboard, "widget_update");
    };

    useEffect(() => {
        if (!enabled) return;
        if (!user?.uid) return;
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) return;

        return onSnapshot(
            query(
                collection(firestore, dashboardsPath).withConverter(timestampToDateConverter),
                where("deleted", "==", false),
                where("users", "array-contains", user.uid),
                orderBy("updated_at", "desc")
            ),
            {
                next: (snapshot) => {
                    const updatedDashboards = snapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as Dashboard;
                    });
                    updateDashboards(updatedDashboards);
                    setDashboardsLoading(false);
                },
                error: (e) => {
                    console.error(e);
                }
            }
        );
    }, [enabled, firebaseApp, dashboardsPath, user?.uid]);

    useEffect(() => {
        if (!enabled) return;
        if (!user?.uid) {
            setTeams([]);
            return;
        }
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !teamsPath) return;

        return onSnapshot(
            query(collection(firestore, teamsPath), where("users", "array-contains", user.uid)).withConverter(timestampToDateConverter),
            {
                next: (snapshot) => {
                    setTeams(snapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        } as Team;
                    }));
                },
                error: (e) => {
                    console.error(e);
                }
            }
        );
    }, [enabled, firebaseApp, teamsPath, user?.uid]);

    const updateDashboard = (dashboardId: string, dashboardData: Partial<Dashboard>, updateType?: DashboardUpdateType) => {
        console.log("updateDashboard", dashboardId, dashboardData)
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const updatedDashboard = {
            ...dashboard,
            ...dashboardData
        };
        return saveDashboard(updatedDashboard, updateType);
    };

    const updateDashboardPage = (dashboardId: string, pageId: string, pageData: Partial<DashboardPage>) => {
        console.log("updateDashboardPage", dashboardId, pageId, pageData)
        if (!firebaseApp) throw Error("useBuildDatakiConfig Firebase not initialised");
        const firestore = getFirestore(firebaseApp);
        if (!firestore || !dashboardsPath) throw Error("useBuildDatakiConfig Firestore not initialised");
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        const updatedPage = {
            ...page,
            ...pageData
        };
        const updatedDashboard = {
            ...dashboard,
            pages: dashboard.pages.map(p => p.id === pageId ? updatedPage : p)
        };
        return saveDashboard(updatedDashboard, "page_update");
    };

    const revertDashboard = async (dashboard: Dashboard) => {
        console.log("revertDashboard", dashboard.id);
        const updatedDashboard = {
            id: dashboard.id,
            title: dashboard.title,
            pages: dashboard.pages
        };
        return updateDashboard(dashboard.id, updatedDashboard, "dashboard_revert");
    }

    const addFilterToDashboard = (dashboardId: string, pageId: string, filter: DashboardFilterConfig) => {
        const dashboard = dashboardsRef.current.find(d => d.id === dashboardId);
        if (!dashboard) throw Error("addDashboardWidget: Dashboard not found");
        const page = dashboard.pages.find(p => p.id === pageId);
        if (!page) throw Error("addDashboardWidget: Page not found");
        if (!page.filters) {
            page.filters = [];
        }
        if (page.filters.find(f => f.key === filter.key)) {
            console.warn("Filter already exists in dashboard", filter.key);
            return Promise.reject(new Error("Filter already exists in dashboard"));
        }
        page.filters.push(filter);
        return saveDashboard(dashboard, "filter_add");
    }

    return {
        loading: dashboardsLoading,
        apiEndpoint,
        getAuthToken,

        teams,
        getTeam,
        createTeam,
        saveTeam,

        getUser,

        updateDashboardPermissions,

        saveChatSession,
        getChatSession,
        createChatSessionId,
        dashboards,
        createDashboard,
        deleteDashboard,
        saveDashboard,
        updateDashboard,
        updateDashboardPage,
        listenDashboard,
        listenDashboardHistory,
        addDashboardText,
        updateDashboardText,
        addDashboardWidget,
        onWidgetResize,
        onWidgetUpdate,
        onWidgetMove,
        onWidgetRemove,
        onWidgetsRemove,

        addFilterToDashboard,

        listenChatSessions,

        revertDashboard,

        firebaseApp,

        appBarRef
    };
}

export const useDataki = () => useContext(DatakiConfigContext);

export function DatakiProvider({
                                   config,
                                   children
                               }: { config: DatakiConfig, children: React.ReactNode }) {

    return <DatakiConfigContext.Provider value={config}>
        {children}
    </DatakiConfigContext.Provider>;
}

const timestampToDateConverter = {
    toFirestore(data: any) {
        return data; // This can be customized based on your write needs
    },
    fromFirestore(snapshot: any, options: any) {
        const data = snapshot.data(options);
        return convertTimestamps(data);
    }
};

function convertTimestamps(data: any): any {
    if (data instanceof Timestamp) {
        return data.toDate(); // Convert Timestamp directly if the item is a Timestamp
    } else if (Array.isArray(data)) {
        return data.map(item => convertTimestamps(item)); // Process arrays recursively
    } else if (data !== null && typeof data === "object") {
        for (const key in data) {
            data[key] = convertTimestamps(data[key]); // Recursively process object properties
        }
        return data;
    }
    return data; // Return the data if it is neither a Timestamp nor a complex object/array
}

function initializeDashboard(uid: string, dashboardData?: Partial<Dashboard>): Omit<Dashboard, "id"> {
    return {
        created_at: new Date(),
        updated_at: new Date(),
        users: [uid],
        owner: uid,
        permissions: [{
            uid,
            type: "write"
        }],
        pages: [{
            id: randomString(20),
            widgets: [],
            filters: [],
        }],
        deleted: false,
        updated_by: uid,
        updated_type: "dashboard_create",
        ...dashboardData
    };
}

function convertWidgetToDashboardWidget(config: DryChartWidgetConfig | DryTableWidgetConfig, position?: Position): DashboardWidgetConfig {
    return {
        ...config,
        size: config.size ?? DEFAULT_WIDGET_SIZE,
        id: randomString(20),
        position: position ?? {
            x: 0,
            y: 0
        }
    }
}

export function convertWidgetToDashboardConfig(dashboardPage: DashboardPage, widget: DryChartWidgetConfig | DryTableWidgetConfig) {
    const maxYPosition = dashboardPage.widgets.reduce((acc, widget) => {
        const y = widget.position.y + widget.size.height;
        if (y > acc)
            return y;
        return acc;
    }, 0);
    return convertWidgetToDashboardWidget(widget, {
        x: DEFAULT_GRID_SIZE,
        y: maxYPosition + DEFAULT_GRID_SIZE
    });
}

// function saveCredentialInStorage(credential: OAuthCredential | null) {
//     if (!credential) {
//         localStorage.removeItem("googleCredential");
//         return;
//     }
//     const credentialString = JSON.stringify({
//         created_on: new Date(),
//         credential: credential.toJSON()
//     });
//     localStorage.setItem("googleCredential", credentialString);
// }
//
// function loadCredentialFromStorage(): OAuthCredential | null {
//     try {
//         const credentialString = localStorage.getItem("googleCredential");
//         if (!credentialString)
//             return null;
//         const credentialJSON = JSON.parse(credentialString) satisfies {
//             created_on: string,
//             credential: any
//         };
//         const credential = OAuthCredential.fromJSON(credentialJSON.credential);
//         const createdOn = new Date(credentialJSON.created_on);
//         const now = new Date();
//         const diff = now.getTime() - createdOn.getTime();
//         // check the credential is valid
//         if (diff > 1000 * 60 * 60) {
//             console.debug("Google credential expired credential expired");
//             saveCredentialInStorage(null);
//             return null;
//         }
//         return credential;
//     } catch (e) {
//         console.error(e);
//         return null;
//     }
// }
