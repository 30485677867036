import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    CheckIcon,
    DragHandleIcon,
    IconButton,
    LoadingButton,
    Paper,
    SaveIcon,
    Tooltip,
    Typography
} from "@firecms/ui";

import { DashboardFilterConfig, DataSource, DateParams, ParamFilter } from "../types";
import { SQLTableView, useSQLTableConfig } from "./SQLTableView";
import { formatSQL, getDialectFromDataSources } from "../utils/sql";
import { CodeEditor } from "./CodeEditor";
import { DataSourcesSelection } from "./DataSourcesSelection";
import { useFiltersStateView } from "../hooks/useFiltersStateView";

/**
 * This view allows you to run SQL queries and see the output
 * @constructor
 */
export function SQLQueryView({
                                 initialSql,
                                 initialDataSources,
                                 params,
                                 paramFilters,
                                 filters,
                                 onSaved,
                                 onDirtyChange
                             }: {
    initialSql?: string,
    onSaved?: (sql?: string) => Promise<void>
    initialDataSources?: DataSource[],
    params?: DateParams,
    paramFilters?: ParamFilter[],
    filters: DashboardFilterConfig[],
    onDirtyChange?: (dirty: boolean) => void
}) {

    console.log({
        initialSql,
        initialDataSources,
        params,
        paramFilters,
        filters
    })
    const editorRef = useRef<any>(null);
    const [dataSources, setDataSources] = useState<DataSource[]>(initialDataSources ?? []);
    const dialect = getDialectFromDataSources(dataSources);

    const [selectedText, setSelectedText] = useState<string | undefined>();

    const initialFormatted = useRef(initialSql ? formatSQL(initialSql, dialect) : undefined);
    const {
        dateRange,
        paramFilters: paramFiltersState,
        filters: filtersState,
        view: dateRangeView
    } = useFiltersStateView({
        initialDateRange: params ? [params.dateStart ?? null, params.dateEnd ?? null] : undefined,
        initialParamFilters: paramFilters,
        filters,
        dataSources
    });

    const [sql, setSql] = useState<string | undefined>(initialFormatted.current);
    const [dirty, setDirty] = useState<boolean>(false);

    const [saved, setSaved] = useState<boolean>(false);

    // Reset saved state when initialSql changes or after 2 seconds
    useEffect(() => {
        if (initialSql) {
            setSaved(false);
        }
    }, [initialSql]);

    useEffect(() => {
        if (saved) {
            const timeout = setTimeout(() => {
                setSaved(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [saved]);

    const doSave = () => {
        onSaved?.(sql).then(() => {
            initialFormatted.current = sql ? formatSQL(sql, dialect) : sql;
            setSql(initialFormatted.current);
            setDirty(false);
            onDirtyChange?.(false);
            setSaved(true);
        });
    }

    const updateSql = (newSql?: string) => {
        setSql(newSql);
        const newDirty = !initialSql || newSql !== initialFormatted.current;
        setDirty(newDirty);
        onDirtyChange?.(newDirty);
    }

    const sqlTableConfig = useSQLTableConfig({
        dataSources,
        sql: selectedText ?? sql ?? "",
        params: dateRange
            ? {
                dateStart: dateRange[0],
                dateEnd: dateRange[1]
            }
            : undefined,
        paramFilters: paramFiltersState
    });

    useEffect(() => {
        if (initialSql) {
            sqlTableConfig.refreshData();
        }
    }, []);

    return <div className={"flex flex-col h-full w-full px-4 sm:px-8 md:px-16 lg:px-24 xl:px-32 2xl:px-64 py-4"}>

        <div className={"flex flex-row gap-4 mt-8 mb-2 items-center overflow-x-scroll no-scrollbar h-16"}>
            <Typography className={"flex-grow "} variant={"label"}>
                SQL Editor
            </Typography>

            <DataSourcesSelection dataSources={dataSources}
                                  setDataSources={(dataSources) => {
                                      setDataSources(dataSources);
                                  }}/>

            {dateRangeView}
            <Button variant={"text"}
                    className={"text-text-secondary dark:text-text-secondary-dark"}
                    onClick={() => {
                        updateSql(formatSQL(sql ?? "", dialect));
                    }}>
                Format
            </Button>
            {onSaved && <Tooltip
                open={saved}
                side={"top"}
                title={"Updated!"}>

                <IconButton variant={"ghost"} shape={"square"} disabled={!dirty}
                            onClick={doSave}>
                    {saved ? <CheckIcon/> : <SaveIcon/>}
                </IconButton>
            </Tooltip>}
            <LoadingButton variant={"outlined"}
                           loading={sqlTableConfig.dataLoading}
                           onClick={() => sqlTableConfig.refreshData()}>
                Run
            </LoadingButton>
        </div>

        <PanelGroup direction="vertical" className={"flex-grow"}>
            <Panel maxSize={75} defaultSize={40}>
                <CodeEditor
                    defaultLanguage={"sql"}
                    value={sql}
                    onChange={updateSql}
                    sqlDialect={dialect}
                    onMount={(editor) => {
                        editorRef.current = editor;
                        editor.onDidChangeCursorSelection((e: any) => {
                            try {
                                const model = editor.getModel();
                                const selection = editor.getSelection();
                                const text = (model.getValueInRange(selection)).trim();
                                setSelectedText(text ?? undefined);
                            } catch (error) {
                                setSelectedText(undefined);
                            }
                        });
                    }}
                />
            </Panel>
            <PanelResizeHandle className={"h-4 flex justify-center items-center"}>
                <DragHandleIcon size="small" color={"disabled"}/>
            </PanelResizeHandle>
            <Panel maxSize={75}>
                <Paper className={"h-full"}>
                    <SQLTableView sqlTableConfig={sqlTableConfig}/>
                </Paper>
            </Panel>
        </PanelGroup>

    </div>
}
