import React, { ComponentType, memo, useEffect, useState } from "react";
import { Node, NodeProps, NodeResizer, useOnViewportChange, Viewport } from "@xyflow/react";
import { DashboardWidgetConfig, DryTableWidgetConfig, DryWidgetConfig, WidgetSize } from "../../../types";
import { DEFAULT_WIDGET_SIZE } from "../../../utils/widgets";
import { DryChartConfigView } from "../../widgets/DryChartConfigView";
import { useDataki } from "../../../DatakiProvider";
import { ErrorBoundary } from "@firecms/core";
import { DryTableConfigView } from "../../widgets/DryTableConfigView";
import { Button, CopyAllIcon, ForumIcon, IconButton, Tooltip } from "@firecms/ui";
import { WidgetChatSession } from "../../widgets/WidgetChatSession";
import { useDashboardStateContext } from "../DashboardPageView";
import { AddToDashboardDialog } from "../AddToDashboardDialog";

export type ChartNodeProps = {
    widget: DashboardWidgetConfig;
    dashboardId: string;
    pageId: string;
};

function ChartNode(props: NodeProps<Node<ChartNodeProps>>) {

    const { data } = props;
    const datakiConfig = useDataki();
    const dashboardState = useDashboardStateContext();

    const widget = data.widget;

    const dashboardPage = datakiConfig.dashboards.find(d => d.id === data.dashboardId)?.pages.find(p => p.id === data.pageId);

    const [addToDashboardDialogOpen, setAddToDashboardDialogOpen] = React.useState<boolean>(false);

    useEffect(() => {
        setSize(widget.size ?? DEFAULT_WIDGET_SIZE);
    }, [widget.size]);
    const [size, setSize] = useState<WidgetSize>(widget.size ?? DEFAULT_WIDGET_SIZE);

    const [zoom, setZoom] = useState<number>(1);

    const [chatDialogOpen, setChatDialogOpen] = React.useState(false);

    const [resizing, setResizing] = useState<boolean>(false);
    useOnViewportChange({
        // onStart: (viewport: Viewport) => console.log("start", viewport),
        // onChange: (viewport: Viewport) => setZoom(viewport.zoom),
        onEnd: (viewport: Viewport) => setZoom(viewport.zoom),
    });

    const actions = <>
        <Tooltip title={"Add this view to a different dashboard"}>
            <IconButton size={"small"}
                        onClick={() => setAddToDashboardDialogOpen(true)}>
                <CopyAllIcon size={"small"}/>
            </IconButton>
        </Tooltip>
        <Button variant={"outlined"} size={"small"}
                onClick={() => setChatDialogOpen(true)}>
            <ForumIcon size="small"/>
            Edit
        </Button>
    </>;

    const onUpdated = (newConfig: DryWidgetConfig | DryTableWidgetConfig) => {
        if (newConfig.type !== widget.type) {
            console.error("Widget type mismatch", {
                newConfig,
                widget
            });
            return;
        }
        const result = {
            ...widget,
            ...newConfig
        } satisfies DashboardWidgetConfig;
        datakiConfig.onWidgetUpdate(data.dashboardId, data.pageId, widget.id, result);
    };

    // const pendingChangeRef = useRef<{ size: WidgetSize, position: Position }>(null);
    return (
        <div
            key={widget.id}
            style={{
                width: size.width,
                height: size.height
            }}>

            {widget && <AddToDashboardDialog open={addToDashboardDialogOpen}
                                             setOpen={setAddToDashboardDialogOpen}
                                             widget={widget}/>}

            <NodeResizer minWidth={200}
                         minHeight={200}
                         onResizeStart={() => setResizing(true)}
                         onResizeEnd={(event, params) => {
                             const updatedSize = {
                                 width: params.width,
                                 height: params.height
                             };
                             const position = {
                                 x: params.x,
                                 y: params.y
                             };

                             setSize(updatedSize);
                             setResizing(false);
                             dashboardState.onNodeResize(widget.id, {
                                 size: updatedSize,
                                 position
                             });
                         }}
                         onResize={(event, params) => {
                             const updatedSize = {
                                 width: params.width,
                                 height: params.height
                             };
                             setSize(updatedSize);
                         }}
            />

            {widget.type === "chart" && <DryChartConfigView dryConfig={widget}
                                                            actions={actions}
                                                            params={dashboardState.params}
                                                            paramFilters={dashboardState.paramFilters}
                                                            filters={dashboardState.filters}
                                                            selected={props.selected}
                                                            className={props.selected ? "" : ""}
                                                            readOnly={dashboardState.readOnly}
                                                            onRemoveClick={() => dashboardState.onNodesDelete([widget.id])}
                                                            onUpdated={onUpdated}/>}

            {widget.type === "table" && <DryTableConfigView dryConfig={widget}
                                                            actions={actions}
                                                            params={dashboardState.params}
                                                            paramFilters={dashboardState.paramFilters}
                                                            filters={dashboardState.filters}
                                                            selected={props.selected}
                                                            className={props.selected ? "" : ""}
                                                            readOnly={dashboardState.readOnly}
                                                            onRemoveClick={() => dashboardState.onNodesDelete([widget.id])}
                                                            onUpdated={onUpdated}/>}

            <ErrorBoundary>
                {widget && <WidgetChatSession open={chatDialogOpen}
                                              setOpen={setChatDialogOpen}
                                              dryConfig={widget}
                                              initialParamFilters={dashboardState.paramFilters}
                                              params={dashboardState.params}
                                              filters={dashboardState.filters}
                                              dashboardId={data.dashboardId}
                                              dashboardPageId={data.pageId}
                                              dashboardPage={dashboardPage}
                />}
            </ErrorBoundary>

        </div>
    );
}

export default memo(ChartNode) as ComponentType<NodeProps<Node<ChartNodeProps>>>;
