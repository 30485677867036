import { useEffect, useState } from "react";
import { useDataki } from "../DatakiProvider";
import { Dashboard, DashboardUpdateType } from "../types";
import { Card, Chip, cls, getColorSchemeForSeed, HistoryIcon, IconButton, Tooltip, Typography } from "@firecms/ui";
import { useSnackbarController } from "@firecms/core";
import { DashboardPanel } from "./DashboardPanel";

export function DashboardHistoryView({
                                         dashboardId,
                                         onClose,
                                         hidden
                                     }: {
    dashboardId: string,
    onClose?: () => void,
    hidden?: boolean
}) {

    const dataki = useDataki();
    const snackbarController = useSnackbarController();
    const [history, setHistory] = useState<Dashboard[]>([]);

    useEffect(() => dataki.listenDashboardHistory(dashboardId, setHistory), [dashboardId]);

    return <DashboardPanel
        title={<Typography variant={"label"}>
            Dashboard history
        </Typography>}
        onClose={onClose}
        className={cls("p-4", { hidden })}>
        {history.map(dashboardEntry => <Card key={dashboardEntry.revision}>
            <div className={"flex gap-6 items-center p-4"}>
                <div className={"flex flex-col gap-1 flex-1"}>
                    {dashboardEntry.updated_type && <UpdateTypeLabel type={dashboardEntry.updated_type}/>}
                    <div className={"flex gap-2 items-center"}>

                        <Typography variant={"caption"}>
                            {dashboardEntry.updatedByUser?.displayName ?? dashboardEntry.updatedByUser?.email ?? dashboardEntry.updated_by}</Typography>
                        <Typography color={"secondary"}
                                    variant={"caption"}> {dashboardEntry.updated_at.toLocaleString()}</Typography>
                    </div>

                </div>
                <Tooltip title={"Revert to this version"}>
                    <IconButton size={"small"}
                                onClick={() => {
                                    dataki.revertDashboard(dashboardEntry)
                                        .then(() => {
                                            snackbarController.open({
                                                message: "Dashboard reverted",
                                                type: "success"
                                            });
                                        })
                                        .catch((error) => {
                                            console.error("Error reverting dashboard", error);
                                            snackbarController.open({
                                                message: "Error reverting dashboard",
                                                type: "error"
                                            });
                                        });
                                    onClose?.();
                                }}>
                        <HistoryIcon/>
                    </IconButton>
                </Tooltip>
            </div>

        </Card>)}
    </DashboardPanel>;
}

function UpdateTypeLabel({ type }: { type: DashboardUpdateType }) {
    const color = getColorSchemeForSeed(type);
    return <Chip
        colorScheme={color}
        size={"small"}>{getTypeText(type)}</Chip>;
}

function getTypeText(type: DashboardUpdateType) {
    switch (type) {
        case "text_update":
            return "Text update";
        case "title_update":
            return "Title update";
        case "widget_create":
            return "Widget created";
        case "widget_update":
            return "Widget updated";
        case "widget_move":
            return "Widget moved";
        case "widget_resize":
            return "Widget resized";
        case "widget_remove":
            return "Widget removed";
        case "widgets_remove":
            return "Widgets removed";
        case "page_update":
            return "Page updated";
        case "dashboard_delete":
            return "Dashboard deleted";
        case "dashboard_create":
            return "Dashboard created";
        case "dashboard_revert":
            return "Dashboard reverted";
        case "filter_add":
            return "Filter added";
        case "filter_update":
            return "Filter updated";
        case "filter_remove":
            return "Filter removed";
    }
    console.warn("getTypeText: Unknown update type", type);
    return type;
}
