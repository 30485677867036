import React from "react";
import { getNodesBounds, getViewportForBounds, ReactFlowInstance, useReactFlow } from "@xyflow/react";
import { toPng } from "html-to-image";
import { DownloadIcon, IconButton } from "@firecms/ui";
import { useModeController } from "@firecms/core";
import { downloadImage } from "../../utils/downloadImage";

export function DownloadButton({
                                   reactFlow
                               }: {
    reactFlow: ReactFlowInstance
}) {

    const { mode } = useModeController();
    const { getNodes } = reactFlow;

    const onClick = () => {
        // we calculate a transform for the nodes so that all nodes are visible
        // we then overwrite the transform of the `.react-flow__viewport` element
        // with the style option of the html-to-image library

        const paperNode = getNodes().find((node) => node.type === "paper");

        const imageWidth = paperNode?.width;
        const imageHeight = paperNode?.height;

        if (!imageWidth || !imageHeight) {
            throw Error("Paper node not found");
        }
        console.log(paperNode);

        const nodesBounds = getNodesBounds([paperNode]);
        console.log(nodesBounds);
        // const nodesBounds = getNodesBounds(getNodes());
        const transform = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 1, 1, 0);

        console.log("transform", transform);
        const node = document.querySelector(".react-flow__viewport");
        if (!node) {
            throw Error("Viewport not found");
        }
        toPng(node as HTMLElement, {
            backgroundColor: mode === "dark" ? "#18181c" : "#fff",
            width: imageWidth,
            height: imageHeight,
            style: {
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
                transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
            },
        }).then((url) => downloadImage(url, "dashboard.png"));
    };

    return (
        <IconButton variant="ghost" onClick={onClick}>
            <DownloadIcon/>
        </IconButton>
    );
}

