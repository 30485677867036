import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
// @ts-ignore
import { createPortal } from "react-dom";

import { Document, Image, Page, pdf, StyleSheet } from "@react-pdf/renderer";
import html2canvas from "html2canvas";

import {
    Background,
    BackgroundVariant,
    Node,
    ReactFlow,
    ReactFlowInstance,
    SelectionMode,
    useNodesState
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import {
    cls,
    defaultBorderMixin,
    DragHandleIcon,
    ForumIcon,
    HistoryIcon,
    IconButton,
    LockIcon,
    Tooltip,
    useInjectStyles
} from "@firecms/ui";
import { Dashboard, DashboardPage, DateParams, ParamFilter, Position } from "../../types";
import ChartNode from "./nodes/ChartNode";
import { useDataki } from "../../DatakiProvider";
import PaperNode from "./nodes/PaperNode";
import { DEFAULT_GRID_SIZE, DEFAULT_PAPER_SIZE } from "../../utils/widgets";
import { DashboardMenubar } from "./DashboardMenubar";
import { DashboardState, useCreateDashboardState } from "../../hooks/useCreateDashboardState";
import TextNode from "./nodes/TextNode";
import { convertDashboardWidgetsToNodes, convertNodesToWidgets } from "../../utils/dashboard";
import { ShareDialog } from "../ShareDialog";
import { DashboardHistoryView } from "../DashboardHistoryView";
import { DashboardChatView } from "../DashboardChatView";
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { getSidePanelWidth, saveSidePanelWidth } from "../../utils/side_panels";
import { useFiltersStateView } from "../../hooks/useFiltersStateView";
import { getInitialParamFilters } from "../../utils/filters";
import { measureElementSize, useResizeObserver } from "../../utils/useResizeObserver";

const DashboardStateContext = React.createContext<DashboardState | undefined>(undefined);

export function useDashboardStateContext() {
    const context = React.useContext(DashboardStateContext);
    if (context === undefined) {
        throw new Error("useDashboardStateContext must be used within a DashboardStateContext");
    }
    return context;
}

const nodeTypes = {
    paper: PaperNode,
    chart: ChartNode,
    text: TextNode
};

export const DashboardPageView = function DashboardPageView({
                                                                page,
                                                                dashboard,
                                                                initialViewPosition,
                                                                readOnly = false
                                                            }: {
    page: DashboardPage,
    dashboard: Dashboard,
    initialViewPosition?: Position,
    readOnly?: boolean,
}) {

    const parentRef = useRef<HTMLDivElement>(null);
    const flowContainerRef = useRef<HTMLDivElement>(null);

    const measuredContainerSize = useResizeObserver(flowContainerRef);
    const parentContainerSize = useResizeObserver(parentRef);

    const draggingPanel = useRef(false);

    const {
        dateRange,
        paramFilters,
        setParamFilters,
        filters,
        view: dateRangeView
    } = useFiltersStateView({
        initialDateRange: undefined,
        initialParamFilters: getInitialParamFilters(page.filters ?? []),
        filters: page.filters,
        onFilterUpdate: (updatedFilter) => {

            const currentParamFilter = paramFilters.find((f) => f.key === updatedFilter.key);

            const updatedParamFilter: ParamFilter = {
                key: updatedFilter.key,
                value: currentParamFilter?.value ?? null,
                operator: currentParamFilter?.operator ?? undefined,
                type: updatedFilter.type ?? currentParamFilter?.type,
            }

            setParamFilters((filters) => {
                const existingFilter = filters.find((f) => f.key === updatedParamFilter.key);
                if (existingFilter) {
                    return filters.map((f) => f.key === updatedParamFilter.key ? updatedParamFilter : f);
                }
                return [...filters, updatedParamFilter];
            });

            const updatedDashboard: Dashboard = {
                ...dashboard,
                pages: dashboard.pages.map((p) => {
                    if (p.id === page.id) {
                        return {
                            ...p,
                            filters: p.filters.map((f) => f.key === updatedFilter.key ? updatedFilter : f)
                        }
                    }
                    return p;
                })
            };
            datakiConfig.updateDashboard(dashboard.id, updatedDashboard, "filter_update");
        },
        onFilterRemove: (removedFilter) => {
            const updatedDashboard: Dashboard = {
                ...dashboard,
                pages: dashboard.pages.map((p) => {
                    if (p.id === page.id) {
                        return {
                            ...p,
                            filters: p.filters.filter((f) => f.key !== removedFilter.key)
                        }
                    }
                    return p;
                })
            };
            datakiConfig.updateDashboard(dashboard.id, updatedDashboard, "filter_remove");
            setParamFilters((filters) => filters.filter((f) => f.key !== removedFilter.key));
        }
    });

    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [panelOpen, setPanelOpen] = useState<"dashboard_chat" | "dashboard_history" | null>(null);
    const [historyPanelElement, setHistoryPanelElement] = useState<HTMLDivElement | null>(null);
    const [chatPanelElement, setChatPanelElement] = useState<HTMLDivElement | null>(null);

    const [cameraLocked, setCameraLocked] = useState(true);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

    const openPanel = (panel: "dashboard_chat" | "dashboard_history" | null) => {
        setPanelOpen(panel);
        setTimeout(() => {
            adjustViewport();
        }, 100)
    }

    const prevContainerSize = useRef<{ width: number, height: number } | null>(null);
    const lastViewportAdjustmentTimestamp = useRef<number>(0);

    function adjustViewport() {

        if (draggingPanel.current) {
            console.log("Dragging panel, not adjusting viewport");
            return;
        }

        if (lastViewportAdjustmentTimestamp.current && Date.now() - lastViewportAdjustmentTimestamp.current < 16) {
            return;
        }

        if (!flowContainerRef.current) {
            return;
        }

        const containerSize = measureElementSize(flowContainerRef.current);

        if (cameraLocked && reactFlowInstance && containerSize && prevContainerSize.current) {
            const currentViewport = reactFlowInstance.getViewport();

            const scaleFactor = containerSize.width / prevContainerSize.current.width;
            const newZoom = Math.min(1, currentViewport.zoom * scaleFactor);

            // const newX = worldCenterX - (containerSize.width / 2) / newZoom;
            const newX = currentViewport.x * scaleFactor;
            const newY = currentViewport.y * scaleFactor;

            reactFlowInstance.setViewport(
                {
                    x: newX,
                    y: newY,
                    zoom: newZoom
                },
                { duration: 300 }
            );

            prevContainerSize.current = containerSize;

            console.log("Adjusted viewport", {
                containerSize,
                currentViewport,
                prevContainerSize,
                scaleFactor,
                newZoom,
            });
            lastViewportAdjustmentTimestamp.current = Date.now();
        } else if (containerSize) {
            console.log("Container size changed, not adjusting viewport")
            prevContainerSize.current = containerSize;
        }
    }

    const onSharedClick = useCallback(() => {
        setShareDialogOpen(true);
    }, []);

    const params: DateParams = useMemo(() => ({
        dateStart: dateRange[0] ?? null,
        dateEnd: dateRange[1] ?? null
    }), [dateRange]);

    const datakiConfig = useDataki();

    const appBarRef = datakiConfig.appBarRef;

    const onRemoveClick = useCallback((id: string) => {
        datakiConfig.onWidgetRemove(dashboard.id, page.id, id);
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
    }, [dashboard.id, page.id])

    const paperSize = page.paper?.size ?? DEFAULT_PAPER_SIZE;
    const paperPosition = page.paper?.position ?? {
        x: 0,
        y: 0
    };

    useEffect(() => {
        // Calculate new zoom so that paper width fits container width.
        adjustViewport();
    }, [parentContainerSize, cameraLocked, reactFlowInstance, paperSize.width]);

    const lockCamera = () => {
        setCameraLocked(!cameraLocked);
    }

    const exportToPDF = async () => {
        if (!reactFlowInstance) return;

        try {
            // Store current viewport
            const currentViewport = reactFlowInstance.getViewport();

            // Get all nodes to calculate total bounds
            const allNodes = reactFlowInstance.getNodes();
            if (allNodes.length === 0) return;

            // Calculate bounds to contain all nodes
            let minX = Infinity;
            let minY = Infinity;
            let maxX = -Infinity;
            let maxY = -Infinity;

            allNodes.forEach(node => {
                const x = node.position.x;
                const y = node.position.y;
                const width = node.width || 0;
                const height = node.height || 0;

                minX = Math.min(minX, x);
                minY = Math.min(minY, y);
                maxX = Math.max(maxX, x + width);
                maxY = Math.max(maxY, y + height);
            });

            // Add minimal padding around the bounds
            const padding = 20;
            minX -= padding;
            minY -= padding;
            maxX += padding;
            maxY += padding;

            // Calculate dimensions
            const width = maxX - minX;
            const height = maxY - minY;

            // Get the ReactFlow viewport
            const reactFlowViewport = document.querySelector(".react-flow__viewport");
            if (!reactFlowViewport) return;

            // Temporarily fit view to show all nodes
            reactFlowInstance.fitBounds({
                x: minX,
                y: minY,
                width: width,
                height: height
            }, { padding: 0 });

            // Wait for the view to update
            await new Promise(resolve => setTimeout(resolve, 300));

            // Get all node elements
            const nodeElements = document.querySelectorAll(".react-flow__node");
            if (nodeElements.length === 0) return;

            // Create a temporary container to arrange nodes for capture
            const tempContainer = document.createElement("div");
            tempContainer.style.position = "absolute";
            tempContainer.style.left = "-9999px";
            tempContainer.style.width = `${width}px`;
            tempContainer.style.height = `${height}px`;
            tempContainer.style.backgroundColor = "#ffffff";
            document.body.appendChild(tempContainer);

            // Clone each node and position it properly in the temp container
            nodeElements.forEach(node => {
                const clone = node.cloneNode(true) as HTMLElement;
                const originalNode = allNodes.find(n => n.id === node.getAttribute("data-id"));
                if (originalNode) {
                    const x = originalNode.position.x - minX;
                    const y = originalNode.position.y - minY;
                    clone.style.position = "absolute";
                    clone.style.left = `${x}px`;
                    clone.style.top = `${y}px`;
                    clone.style.transform = "none";
                    tempContainer.appendChild(clone);
                }
            });

            // Create canvas from the temp container
            const canvas = await html2canvas(tempContainer, {
                backgroundColor: "#ffffff",
                scale: 2, // High quality
                logging: false,
                useCORS: true,
                allowTaint: true,
            });

            // Clean up
            document.body.removeChild(tempContainer);

            // Restore original viewport
            reactFlowInstance.setViewport(currentViewport);

            const imageData = canvas.toDataURL("image/png", 1.0);

            // Create PDF with canvas dimensions
            const pdfStyles = StyleSheet.create({
                page: {
                    padding: 0,
                },
                image: {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain"
                }
            });

            const MyDocument = () => (
                <Document>
                    <Page size={[canvas.width, canvas.height]} style={pdfStyles.page}>
                        <Image src={imageData} style={pdfStyles.image}/>
                    </Page>
                </Document>
            );

            // Generate and download PDF
            const blob = await pdf(<MyDocument/>).toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${dashboard.title || "Dashboard"}_${page.title || "Page"}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting PDF:", error);
            // datakiConfig.showSnackbar?.({
            //     message: 'Failed to export PDF',
            //     type: 'error'
            // });
        }
    };

    const [nodes, setNodes, onNodesChange] = useNodesState<any>(convertDashboardWidgetsToNodes(
        {
            widgets: page.widgets,
            paperSize,
            paperPosition,
            dashboardId: dashboard.id,
            pageId: page.id
        }));

    const nodesRef = React.useRef(nodes);
    useEffect(() => {
        nodesRef.current = nodes;
    }, [nodes]);

    useEffect(() => {
        const selectedNodeIds = nodesRef.current.filter((node) => node.selected).map((node) => node.id);

        setNodes(convertDashboardWidgetsToNodes(
            {
                widgets: page.widgets,
                paperSize,
                paperPosition,
                dashboardId: dashboard.id,
                pageId: page.id,
                selectedNodeIds
            }));
    }, [page.id, page.widgets, params]);

    useInjectStyles("dashboard", styles);

    const onNodesUpdate = (updatedNodes: Node<any>[]) => {

        setNodes(updatedNodes);

        const currentWidgets = convertNodesToWidgets(updatedNodes);
        const updatedDashboard: Dashboard = {
            ...dashboard,
            pages: dashboard.pages.map((p) => {
                if (p.id === page.id) {
                    return {
                        ...p,
                        widgets: currentWidgets
                    }
                }
                return p;
            })
        };
        datakiConfig.updateDashboard(dashboard.id, updatedDashboard, "widget_update");
    }

    const dashboardState = useCreateDashboardState({
        dashboard,
        page,
        nodes,
        onNodesUpdate,
        params,
        onRemoveClick,
        paramFilters,
        setParamFilters,
        filters,
        readOnly
    });

    const sidePanelWidth = panelOpen ? getSidePanelWidth(panelOpen) : 0;

    const panelRef = useRef<ImperativePanelHandle>(null);

    useEffect(() => {
        if (panelOpen) {
            const sidePanelWidth = getSidePanelWidth(panelOpen);
            panelRef.current?.resize(sidePanelWidth);
        }
    }, [panelOpen]);

    return (
        <DashboardStateContext value={dashboardState}>

            <ShareDialog dashboard={dashboard}
                         open={shareDialogOpen}
                         onOpenChange={setShareDialogOpen}/>

            <div className={"flex w-full h-full relative"}>
                {/*<Tooltip title="Export to PDF" className={"absolute top-8 z-50"}>*/}
                {/*    <IconButton*/}
                {/*        onClick={exportToPDF}>*/}
                {/*        <PictureAsPdfIcon/>*/}
                {/*    </IconButton>*/}
                {/*</Tooltip>*/}
                <div className={"flex-1 h-full"}
                     ref={parentRef}
                     style={{
                         width: "calc(100% - 3rem)"
                     }}>
                    <PanelGroup direction="horizontal" className={"w-full h-full"}>

                        <Panel maxSize={100}
                               order={1}
                               id={"main"}
                               defaultSize={sidePanelWidth ? 100 - sidePanelWidth : 100}
                               minSize={30}>
                            <div className={"flex flex-col w-full h-full relative"}>

                                {reactFlowInstance && <>
                                    {appBarRef.current && createPortal(
                                        <>
                                            <DashboardMenubar dashboard={dashboard}
                                                              dashboardState={dashboardState}
                                                              onSharedClick={onSharedClick}
                                                              reactFlow={reactFlowInstance}
                                                              className={"flex-1 shrink-1"}
                                                              readOnly={readOnly}
                                                              onNewWidgetClick={() => {
                                                                  if (panelOpen === "dashboard_chat") {
                                                                      openPanel(null);
                                                                  } else {
                                                                      openPanel("dashboard_chat");
                                                                  }
                                                              }}/>
                                            {dateRangeView}
                                        </>,
                                        appBarRef.current
                                    )}

                                </>}

                                <div
                                    ref={flowContainerRef}
                                    onDragStart={() => {
                                        console.log("dragging");
                                        draggingPanel.current = true;
                                    }}
                                    onDragEnd={() => {
                                        console.log("dragging ended");
                                        draggingPanel.current = false;
                                        adjustViewport();
                                    }}
                                    className={"relative w-full h-full bg-surface-50 dark:bg-surface-950 dark:bg-opacity-80 flex-1"}>
                                    {/*<div*/}
                                    {/*    className={cls("absolute top-4 right-4 z-10 bg-white dark:bg-surface-900 rounded-2xl border", defaultBorderMixin)}>*/}
                                    {/*  */}
                                    {/*</div>*/}

                                    <IconButton size={"smallest"}
                                                variant={"ghost"}
                                                shape={"square"}
                                                className={cls("absolute top-4 right-4 z-10", cameraLocked ? "bg-surface-200 dark:bg-surface-800" : "bg-surface-50 dark:bg-surface-900")}
                                                onClick={adjustViewport}>
                                        <LockIcon size={"smallest"}/>
                                    </IconButton>

                                    {measuredContainerSize && <ReactFlow
                                        panOnScroll={true}
                                        multiSelectionKeyCode={"Shift"}
                                        nodes={nodes}
                                        selectionOnDrag={!readOnly}
                                        panOnDrag={false}
                                        selectNodesOnDrag={!readOnly}
                                        snapToGrid={true}
                                        selectionMode={SelectionMode.Partial}
                                        snapGrid={[DEFAULT_GRID_SIZE, DEFAULT_GRID_SIZE]}
                                        zoomOnScroll={false}
                                        zoomOnPinch={true}
                                        minZoom={0.3}
                                        maxZoom={1.8}
                                        onInit={setReactFlowInstance}
                                        defaultViewport={{
                                            x: Math.max((measuredContainerSize.width - paperSize.width) / 2, DEFAULT_GRID_SIZE) - (paperPosition.x),
                                            y: initialViewPosition ? -initialViewPosition.y + 100 : -paperPosition.y + 100,
                                            zoom: 1
                                        }}
                                        onNodesDelete={readOnly ? undefined : (nodes) => dashboardState.onNodesDelete(nodes.map((node) => node.id))}
                                        preventScrolling={false}
                                        onNodesChange={readOnly
                                            ? undefined
                                            : (change) => {
                                                onNodesChange(change);
                                                dashboardState.updateWidgetsBasedOnChange(change);
                                            }}
                                        nodeTypes={nodeTypes}
                                        nodesDraggable={!readOnly}
                                        nodesConnectable={!readOnly}
                                        elementsSelectable={!readOnly}
                                    >

                                        <Background gap={[DEFAULT_GRID_SIZE, DEFAULT_GRID_SIZE]}
                                                    color="#888"
                                                    variant={BackgroundVariant.Dots}/>

                                        {/*<MiniMap nodeStrokeWidth={3}*/}
                                        {/*         className={"dark:bg-surface-900"}*/}
                                        {/*         maskColor={"#88888822"}*/}
                                        {/*         nodeColor={"#66666622"}/>*/}

                                    </ReactFlow>}
                                </div>
                            </div>
                        </Panel>

                        {!readOnly && <PanelResizeHandle
                            onDragging={(isDragging) => {

                                console.log("Panel dragging", isDragging);
                                draggingPanel.current = isDragging;

                                // When resize ends, adjust the viewport
                                if (!isDragging) {
                                    adjustViewport();
                                }
                            }}
                            className={cls("w-4 flex justify-center items-center bg-white hover:bg-surface-50 dark:bg-surface-950 dark:hover:bg-surface-800 border-l border-r", defaultBorderMixin, {
                                hidden: !panelOpen
                            })}>
                            <DragHandleIcon size="small" color={"disabled"} className={"rotate-90"}/>
                        </PanelResizeHandle>}
                        {!readOnly && <Panel id={panelOpen ?? "nope"}
                                             ref={panelRef}
                                             onResize={(size) => {
                                                 if (panelOpen) {
                                                     saveSidePanelWidth(panelOpen, size);
                                                 }
                                             }}
                                             order={2}
                                             minSize={panelOpen ? 30 : 0}
                                             defaultSize={panelOpen ? sidePanelWidth : 0}>

                            <div ref={setHistoryPanelElement}
                                 className={cls("h-full w-full", { hidden: panelOpen !== "dashboard_history" })}/>

                            <div ref={setChatPanelElement}
                                 className={cls("h-full w-full", { hidden: panelOpen !== "dashboard_chat" })}/>

                        </Panel>}

                    </PanelGroup>
                </div>

                {historyPanelElement && createPortal(<DashboardHistoryView dashboardId={dashboard.id}
                                                                           onClose={() => openPanel(null)}/>, historyPanelElement)}

                {chatPanelElement && reactFlowInstance && createPortal(<DashboardChatView dashboardId={dashboard.id}
                                                                                          dashboardPageId={page.id}
                                                                                          dashboard={dashboard}
                                                                                          onClose={() => openPanel(null)}
                                                                                          paramFilters={paramFilters}
                                                                                          filters={filters}
                                                                                          reactFlow={reactFlowInstance}
                                                                                          dateRange={dateRange}/>, chatPanelElement)}

                {!readOnly && <div
                    className={cls("w-12 h-full flex flex-col gap-2 p-1 border-l bg-surface-50 dark:bg-surface-950", defaultBorderMixin)}>
                    <IconButton
                        toggled={panelOpen === "dashboard_chat"}
                        onClick={() => {
                            if (panelOpen === "dashboard_chat") {
                                openPanel(null);
                            } else {
                                openPanel("dashboard_chat");
                            }

                        }}>
                        <ForumIcon/>
                    </IconButton>

                    <Tooltip title={"Dashboard history"}>
                        <IconButton
                            toggled={panelOpen === "dashboard_history"}
                            onClick={() => {
                                if (panelOpen === "dashboard_history") {
                                    openPanel(null);
                                } else {
                                    openPanel("dashboard_history");
                                }
                            }}>
                            <HistoryIcon/>
                        </IconButton>
                    </Tooltip>
                </div>}
            </div>
        </DashboardStateContext>
    );
};

const styles = `

.react-flow__resize-control.line {
    border-color: transparent;
}
.react-flow__resize-control.handle {
    border: none;
    width: 10px;
    height: 10px;
    background-color: transparent;
}
.react-flow__resize-control.line.right {
    border-right-width: 10px;
}
.react-flow__resize-control.line.left {
    border-left-width: 10px;
}
.react-flow__resize-control.line.top {
    border-top-width: 10px;
}
.react-flow__resize-control.line.bottom {
    border-bottom-width: 10px;
}
`;
