import { Node, NodeProps, NodeResizer } from "@xyflow/react";
import { ComponentType, memo, useState } from "react";
import { WidgetSize } from "../../../types";
import { useDataki } from "../../../DatakiProvider";

export type PaperNodeProps = {
    dashboardId: string;
    pageId: string;
    width: number;
    height: number;
    x: number;
    y: number;
}

function PaperNode(props: NodeProps<Node<PaperNodeProps>>) {

    const datakiConfig = useDataki();

    const { data } = props;
    const [size, setSize] = useState<WidgetSize>({
        width: data.width,
        height: data.height,
    });

    return (
        <div
            key={"paper-node"}
            className={"bg-white dark:bg-surface-900 rounded-lg border border-surface-100 dark:border-surface-800 dark:border-opacity-80"}
            style={{
                width: size.width,
                height: size.height,
            }}
        >
            <NodeResizer minWidth={300}
                         minHeight={300}
                         lineClassName={"pointer-events-auto"}
                         onResize={(event, params) => {
                             const updatedSize = {
                                 width: params.width,
                                 height: params.height
                             };
                             const updatedPosition = {
                                 x: params.x,
                                 y: params.y
                             };
                             setSize(updatedSize);
                             datakiConfig.updateDashboardPage(data.dashboardId, data.pageId, {
                                 paper: {
                                     size: updatedSize,
                                     position: updatedPosition
                                 }
                             });
                         }}
            />
        </div>
    );

}

export default memo(PaperNode) as ComponentType<NodeProps<Node<PaperNodeProps>>>;
