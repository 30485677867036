import React, { useEffect, useState } from "react";
import equal from "react-fast-compare"

import { hydrateChartConfig } from "../../api";
import { useDataki } from "../../DatakiProvider";
import {
    DashboardFilterConfig,
    DateParams,
    DryChartWidgetConfig,
    DryWidgetConfig,
    ParamFilter,
    WidgetConfig
} from "../../types";
import { CircularProgressCenter, ErrorBoundary, mergeDeep, useModeController } from "@firecms/core";
import { ChartView } from "./ChartView";
import {
    cls,
    DownloadIcon,
    FilterAltIcon,
    IconButton,
    RefreshIcon,
    RemoveIcon,
    SettingsIcon,
    Tooltip,
    Typography
} from "@firecms/ui";
import { ConfigViewDialog } from "./ConfigViewDialog";
import { toPng } from "html-to-image";
import { downloadImage } from "../../utils/downloadImage";
import { ExecutionErrorView } from "./ExecutionErrorView";
import {
    DEFAULT_WIDGET_SIZE,
    getConfigWithoutSize,
    getUsedParamsForConfig,
    isConfigRelatedToParam
} from "../../utils/widgets";

type LoadedConfig = {
    dryConfig: DryChartWidgetConfig,
    params?: DateParams,
    paramFilters?: ParamFilter[]
};

export function DryChartConfigView({
                                       dryConfig,
                                       params,
                                       paramFilters,
                                       filters,
                                       onUpdated,
                                       onRemoveClick,
                                       maxWidth,
                                       selected,
                                       actions,
                                       className,
                                       readOnly
                                   }: {
    dryConfig: DryChartWidgetConfig,
    params: DateParams,
    paramFilters: ParamFilter[],
    filters: DashboardFilterConfig[],
    onUpdated?: (newConfig: DryChartWidgetConfig) => void,
    onRemoveClick?: () => void,
    maxWidth?: number,
    selected?: boolean,
    actions?: React.ReactNode,
    className?: string,
    readOnly?: boolean
}) {

    const {
        apiEndpoint,
        getAuthToken
    } = useDataki();

    const { mode } = useModeController();

    const [configDialogOpen, setConfigDialogOpen] = React.useState(false);

    const [config, setConfig] = useState<WidgetConfig | null>(null);
    const [hydrationInProgress, setHydrationInProgress] = useState<boolean>(false);
    const [hydrationError, setHydrationError] = useState<Error | null>(null);

    const viewRef = React.useRef<HTMLDivElement>(null);

    const loadedConfig = React.useRef<LoadedConfig | null>(null);

    useEffect(() => {
        const thisConfig = {
            dryConfig: getConfigWithoutSize(dryConfig),
            params,
            paramFilters
        };

        if (!shouldRunHydration(dryConfig, loadedConfig.current, thisConfig)) {
            return;
        }

        if (dryConfig) {
            try {
                loadedConfig.current = {
                    dryConfig: getConfigWithoutSize(dryConfig),
                    params,
                    paramFilters: [...paramFilters]
                };
                makeHydrationRequest(dryConfig);
            } catch (e) {
                console.error(dryConfig);
                console.error("Error parsing dry config", e);
            }
        }
    }, [dryConfig, params, paramFilters]);

    const makeHydrationRequest = async (newDryConfig: DryChartWidgetConfig) => {
        const firebaseToken = await getAuthToken();
        if (!newDryConfig) {
            throw Error("makeHydrationRequest: No code provided");
        }
        setConfig(null);
        setHydrationInProgress(true);
        setHydrationError(null);
        console.debug("Hydrating config", newDryConfig);
        hydrateChartConfig(firebaseToken, apiEndpoint, newDryConfig, params, paramFilters)
            .then((config) => {
                setConfig(mergeDeep(newDryConfig, config));
            })
            .catch(setHydrationError)
            .finally(() => setHydrationInProgress(false));
    };

    const downloadFile = () => {
        toPng(viewRef.current as HTMLElement, {
            backgroundColor: mode === "dark" ? "#18181c" : "#fff",
            width: viewRef.current?.scrollWidth,
            height: viewRef.current?.scrollHeight,
        }).then((url) => downloadImage(url, "chart.png"));
    }

    const onConfigUpdated = (newConfig: DryWidgetConfig) => {
        if (newConfig.type === "chart") {
            if (!newConfig) return;
            makeHydrationRequest(newConfig);
            onUpdated?.(newConfig);
        } else {
            throw new Error("INTERNAL: Unknown widget type: " + newConfig.type);
        }
    };

    const usedParams = getUsedParamsForConfig(dryConfig, paramFilters);

    return <>

        <div
            style={{
                maxWidth
            }}
            className={cls("group flex flex-col w-full h-full bg-white dark:bg-surface-900 border border-surface-100 dark:border-surface-800 dark:border-opacity-80 rounded-lg overflow-hidden",
                selected ? " ring-offset-transparent ring-2 ring-primary ring-opacity-75 ring-offset-2" : "",
                className)}>

            <div
                className={"min-h-[54px] items-center flex flex-row w-full border-b border-surface-100 dark:border-surface-800 dark:border-opacity-80"}>
                <div className={"grow px-3 py-4 flex flex-row items-center gap-2 h-10"}>
                    {(usedParams ?? []).length > 0 && <Tooltip title={"This view is filtered by " + usedParams.map(p => p.key).join(", ")}>
                        <FilterAltIcon size={"smallest"} color={"disabled"}/>
                    </Tooltip>}
                    <Typography variant={"label"}
                                className={" line-clamp-1 "}>{config?.title ?? dryConfig.title}</Typography>
                </div>

                <div className={cls("m-2.5 flex-row gap-1 group-hover:flex nodrag", selected ? "flex" : "hidden")}>
                    <Tooltip title={"Download"}>
                        <IconButton size={"small"} onClick={downloadFile}>
                            <DownloadIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh data"}>
                        <IconButton size={"small"} onClick={() => makeHydrationRequest(dryConfig)}>
                            <RefreshIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>
                    {!readOnly && onRemoveClick && <Tooltip title={"Remove this view"}>
                        <IconButton size={"small"} onClick={onRemoveClick}>
                            <RemoveIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>}

                    {!readOnly && onUpdated && <Tooltip title={"Edit widget configuration"}>
                        <IconButton size={"small"} onClick={() => setConfigDialogOpen(true)}>
                            <SettingsIcon size={"small"}/>
                        </IconButton>
                    </Tooltip>}

                    {!readOnly && actions}

                </div>

            </div>

            {hydrationInProgress && <CircularProgressCenter/>}

            {!hydrationInProgress && !hydrationError && <>
                {config?.chart && (
                    <ErrorBoundary>
                        <ChartView
                            ref={viewRef}
                            size={dryConfig?.size ?? DEFAULT_WIDGET_SIZE}
                            config={config?.chart}/>
                    </ErrorBoundary>
                )}
            </>}

            {!hydrationInProgress && hydrationError && (
                <ExecutionErrorView executionError={hydrationError}/>
            )}

            <ErrorBoundary>
                {dryConfig && <ConfigViewDialog open={configDialogOpen}
                                                setOpen={setConfigDialogOpen}
                                                dryConfig={dryConfig}
                                                params={params}
                                                paramFilters={paramFilters}
                                                filters={filters}
                                                onUpdate={onConfigUpdated}
                />}
            </ErrorBoundary>
        </div>

    </>;
}

function shouldRunHydration(config: DryChartWidgetConfig, a: LoadedConfig | null, b: LoadedConfig | null) {
    if (!a || !b) return true;
    if (a.dryConfig.sql !== b.dryConfig.sql) return true;
    if (!equal(a.dryConfig.chart, b.dryConfig.chart)) return true;
    if (!equal(a.params, b.params)) return true;
    if (!equal(a.paramFilters, b.paramFilters)) {
        return (b.paramFilters ?? []).some(p => isConfigRelatedToParam(config, p));
    }
    return false;
}
