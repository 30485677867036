import React, { useEffect } from "react";

import { CircularProgressCenter } from "@firecms/core";
import { useDataki } from "../DatakiProvider";
import { Button, cls, ForumIcon, Tooltip, Typography } from "@firecms/ui";
import { ChatSession } from "../types";

export function ChatHistory({
                                dashboardId,
                                onEntryClick,
                                onNewChatClick
                            }: {
    dashboardId?: string,
    onEntryClick?: (session: ChatSession) => void,
    onNewChatClick?: () => void
}) {

    const [showAllSessions, setShowAllSessions] = React.useState(false);
    // const [showAllDashboards, setShowAllDashboards] = React.useState(false);

    const {
        listenChatSessions,
    } = useDataki();

    const [sessions, setSessions] = React.useState<ChatSession[] | undefined>(undefined);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        return listenChatSessions(
            {
                dashboardId,
                onChatSessionsUpdate: async (updatedSessions) => {
                    setSessions(updatedSessions);
                    setLoading(false);
                }
            });
    }, [dashboardId]);

    if (loading || sessions === undefined) {
        return <CircularProgressCenter/>;
    }

    const displayedSessions = showAllSessions ? sessions : sessions?.slice(0, 10);
    // const displayedDashboards = showAllDashboards ? dashboards : dashboards?.slice(0, 5);

    const includeShowAllSessions = sessions?.length > 5;
    // const includeShowAllDashboards = dashboards?.length > 5;

    return (

        <>

            {/*<DrawerLogo logo={Logo}/>*/}

            <div className={"flex-grow overflow-scroll no-scrollbar my-8 p-4"}>

                <Tooltip title={"Start new chat"} side={"bottom"}>
                    <Button
                        onClick={onNewChatClick}
                        variant={"outlined"} className={"ml-4 mb-8 rounded-2xl"}>
                        <ForumIcon size="small"/>
                        New chat
                    </Button>
                </Tooltip>

                <>

                    {displayedSessions?.length > 0 && <>
                        <Typography variant={"label"}
                                    color={"secondary"}
                                    className={"ml-4 py-3 uppercase text-xs flex gap-2"}>
                            <ForumIcon size={"smallest"} color={"primary"}/>
                            Recent chats
                        </Typography>

                        {displayedSessions?.map((session, index) => {
                            const firstMessage = session.messages[0];
                            // const charsLimit = 50;
                            // const firstChars = firstMessage?.text.slice(0, charsLimit) ?? "Dataki session started";
                            const firstChars = firstMessage?.text ?? "Dataki session started";
                            return (
                                <div
                                    key={index}
                                    onClick={() => onEntryClick?.(session)}
                                    style={{
                                        width: "100%",
                                    }}
                                    className={cls("flex flex-col justify-between px-4 py-3 cursor-pointer",
                                        "rounded-lg truncate",
                                        "hover:bg-slate-300 hover:bg-opacity-60 dark:hover:bg-surface-700 dark:hover:bg-opacity-60 text-surface-800 dark:text-surface-200 hover:text-surface-900 hover:dark:text-white",
                                        "mr-8",
                                        "font-medium text-sm"
                                    )}
                                >
                                    {/*<Typography variant={"label"}*/}
                                    {/*            className={"whitespace-nowrap"}>{firstChars}{(firstMessage?.text ?? "").length > charsLimit ? "..." : ""}</Typography>*/}

                                    <Typography variant={"label"}
                                                className={"whitespace-nowrap truncate  cursor-pointer"}>{firstChars}</Typography>
                                    {/*<Typography variant={"caption"}*/}
                                    {/*            color={"secondary"}*/}
                                    {/*            className={"whitespace-nowrap"}>{session.created_at.toLocaleString()}</Typography>*/}
                                </div>
                            );
                        })}

                        {includeShowAllSessions &&
                            <Button className={"px-2 m-2 text-text-secondary dark:text-text-secondary-dark"}
                                    size={"small"}
                                    variant={"text"}
                                    onClick={() => {
                                        setShowAllSessions(!showAllSessions)
                                    }}>
                                {showAllSessions ? "Show less" : "Show more"}
                            </Button>}
                    </>}

                    {/*{displayedDashboards?.length > 0 && <>*/}

                    {/*    <Typography variant={"label"}*/}
                    {/*                color={"secondary"}*/}
                    {/*                className={"ml-4 mt-6 py-3 uppercase text-xs flex gap-2"}>*/}
                    {/*        <LineAxisIcon size={"smallest"} color={"primary"}/>*/}
                    {/*        Recent dashboards*/}
                    {/*    </Typography>*/}
                    {/*    {displayedDashboards?.map((dashboard, index) => {*/}
                    {/*            return (*/}
                    {/*                <NavLink to={navigation.homeUrl + "dashboards/" + dashboard.id}*/}
                    {/*                         key={index}*/}
                    {/*                         className={({ isActive }: any) => cls("transition-opacity flex flex-col justify-between px-4 py-3",*/}
                    {/*                             !drawerOpen ? "opacity-0" : "opacity-1",*/}
                    {/*                             "rounded-r-lg truncate",*/}
                    {/*                             "hover:bg-slate-300 hover:bg-opacity-60 dark:hover:bg-surface-700 dark:hover:bg-opacity-60 text-surface-800 dark:text-surface-200 hover:text-surface-900 hover:dark:text-white",*/}
                    {/*                             "mr-8",*/}
                    {/*                             "font-medium text-sm",*/}
                    {/*                             isActive ? "bg-slate-200 bg-opacity-60 dark:bg-surface-800 dark:bg-opacity-30" : ""*/}
                    {/*                         )}*/}
                    {/*                >*/}
                    {/*                    <Typography variant={"label"}>{dashboard.title ?? "Untitled dashboard"}</Typography>*/}
                    {/*                </NavLink>*/}
                    {/*            );*/}
                    {/*        }*/}
                    {/*    )}*/}

                    {/*    {includeShowAllDashboards &&*/}
                    {/*        <Button className={"px-2 m-2 text-text-secondary dark:text-text-secondary-dark"}*/}
                    {/*                size={"small"}*/}
                    {/*                variant={"text"}*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowAllDashboards(!showAllDashboards)*/}
                    {/*                }}>*/}
                    {/*            {showAllDashboards ? "Show less" : "Show more"}*/}
                    {/*        </Button>}*/}
                    {/*</>}*/}

                </>
            </div>
        </>
    );
}
