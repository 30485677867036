// @ts-ignore
import MarkdownIt from "markdown-it";
import { WidgetSize } from "../types";
import JSON5 from "json5";

const md = new MarkdownIt({ html: true });

export type MarkdownElement = {
    type: "html" | "widget";
    loading?: boolean;
    content: string;
    data?: object
    size?: WidgetSize;
};

export function parseMarkdown(text: string): MarkdownElement[] {
    const elements: MarkdownElement[] = [];
    const lines = text.split(/\r?\n/);
    let buffer: string[] = [];
    let inCodeBlock = false;

    for (const line of lines) {
        // Check if we encounter the start or end of a code block
        if (line.trim().toLowerCase().startsWith("```json") || line.trim().toLowerCase().startsWith("```json5")) {
            // If buffer has content, add it as markdown element
            if (buffer.length) {
                elements.push({
                    type: "html",
                    content: md.render(buffer.join("\n"))
                });
                buffer = [];
            }
            inCodeBlock = true;
            continue;
        }
        // Check if we encounter the end of a code block
        else if (line.trim().startsWith("```") && inCodeBlock) {
            try {
                elements.push({
                    type: "widget",
                    loading: false, // Complete widget is not loading
                    content: buffer.join("\n"),
                    data: JSON5.parse(buffer.join("\n")),
                });
            } catch (e) {
                console.warn("Error parsing response JSON", e);
                console.debug("Buffer content:", buffer.join("\n"));
            }
            buffer = [];
            inCodeBlock = false;
            continue;
        }

        // Accumulate lines
        buffer.push(line);
    }

    // Check if there's any remaining markdown content outside of code blocks
    if (buffer.length) {
        if (inCodeBlock) {
            try {
                const missingData = JSON5.parse(buffer.join("\n"));
                elements.push({
                    type: "widget",
                    content: buffer.join("\n"),
                    data: missingData,
                    loading: true // Incomplete widget is loading
                });
            } catch (e) {
                console.warn("Error parsing incomplete JSON block", e);
                console.debug("Buffer content:", buffer.join("\n"));
                // Fallback to treating as HTML if JSON parsing fails
                // elements.push({
                //     type: "html",
                //     content: md.render(buffer.join("\n"))
                // });
            }
        } else {
            elements.push({
                type: "html",
                content: md.render(buffer.join("\n"))
            });
        }
    }

    return elements;
}
