import { CloseIcon, cls, defaultBorderMixin, IconButton, Typography } from "@firecms/ui";
import React, { useEffect } from "react";

export function DashboardPanel({
                                   children,
                                   title,
                                   onClose,
                                   className,
                                   endComponent
                               }: {
    title?: React.ReactNode,
    endComponent?: React.ReactNode,
    children: React.ReactNode,
    onClose?: () => void,
    className?: string
}) {

    // close on ESC
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose?.();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [onClose]);

    return <div
        className={cls("w-full h-full bg-white dark:bg-surface-900", defaultBorderMixin)}>
        <div className={"relative flex flex-col h-full bg-white dark:bg-surface-950"}>
            {title && <div className={cls("border-b px-4 py-2 flex items-center gap-4", defaultBorderMixin)}>
                <IconButton size={"small"} onClick={onClose}><CloseIcon size={"small"}/></IconButton>
                <div className={"flex-grow flex gap-2 items-center"}>{title}</div>
                {endComponent}
            </div>}
            {!title && <div className={"absolute top-2 left-2 z-50"}>
                <IconButton size={"small"} onClick={onClose}><CloseIcon size={"small"}/></IconButton>
            </div>}

            <div className={cls("flex flex-col gap-2 h-full overflow-y-auto grow-1", className)}>
                {children}
            </div>
        </div>
    </div>;

}
