import React, { useEffect, useMemo, useState } from "react";
import {
    BooleanSwitchWithLabel,
    cls,
    DateTimeField,
    defaultBorderMixin,
    IconButton,
    Label,
    MultiSelect,
    MultiSelectItem,
    RemoveIcon,
    Select,
    SelectItem,
    SettingsIcon,
    TextField,
    Typography
} from "@firecms/ui";
import { DatePickerWithRange } from "./DateRange";
import { DashboardFilterConfig, DataSource, FilterOp, FilterValue } from "../types";
import { makeSQLQuery } from "../api";
import { useDataki } from "../DatakiProvider";
import { datasourceToString } from "../utils/datasource";
import { FilterConfigFormDialog } from "./FilterConfigFormDialog";

export interface FilterViewProps {
    filter: DashboardFilterConfig;
    onFilterUpdate?: (newFilter: DashboardFilterConfig) => void;
    onFilterRemove?: (removedFilter: DashboardFilterConfig) => void;
    value?: FilterValue;
    operator?: FilterOp;
    onChange: (value?: FilterValue, operator?: FilterOp) => void;
    className?: string;
    dataSources: DataSource[];
    onSettingsOpen?: (open: boolean) => void;
}

export const FilterView = React.memo(function FilterView({
                                                             filter,
                                                             value,
                                                             onFilterUpdate,
                                                             onFilterRemove,
                                                             operator,
                                                             onChange,
                                                             className,
                                                             dataSources,
                                                             onSettingsOpen
                                                         }: FilterViewProps) {

    const [currentValue, setCurrentValue] = useState<FilterValue>(value ?? null);
    const [currentOperator, setCurrentOperator] = useState<FilterOp>(operator ?? "==");

    const [formOpen, setFormOpen] = useState(false);

    useEffect(() => {
        setCurrentValue(value ?? null);
        setCurrentOperator(operator ?? "==");
    }, [value]);

    const handleValueChange = (newValue: any) => {
        setCurrentValue(newValue);
        onChange(newValue, currentOperator);
    };

    const handleOperatorChange = (newOperator: FilterOp) => {
        setCurrentOperator(newOperator);
        onChange(currentValue, newOperator);
    };

    const renderFilterInput = () => {
        switch (filter.type) {
            case "text_exact":
                return <TextExactFilterInput
                    value={currentValue}
                    onValueChange={handleValueChange}
                    placeholder={filter.placeholder}
                    dataSources={dataSources}
                />;
            case "text_search":
                return <TextSearchFilterInput
                    value={currentValue}
                    onValueChange={handleValueChange}
                    placeholder={filter.placeholder}
                    dataSources={dataSources}
                />;
            case "enum":
                return <EnumFilterInput
                    value={currentValue}
                    onValueChange={handleValueChange}
                    sqlQuery={filter.sqlQuery}
                    options={filter.options || []}
                    placeholder={filter.placeholder}
                    dataSources={dataSources}
                />;
            case "number":
                return <NumberFilterInput
                    value={currentValue}
                    operator={currentOperator}
                    onValueChange={handleValueChange}
                    onOperatorChange={handleOperatorChange}
                    placeholder={filter.placeholder}
                    dataSources={dataSources}
                />;
            case "boolean":
                return <BooleanFilterInput
                    value={currentValue}
                    onValueChange={handleValueChange}
                    dataSources={dataSources}
                />;
            case "date":
                return <DateFilterInput
                    value={currentValue}
                    operator={currentOperator}
                    onValueChange={handleValueChange}
                    onOperatorChange={handleOperatorChange}
                    dataSources={dataSources}
                />;
            case "date_range":
                return <DateRangeFilterInput
                    value={currentValue}
                    onValueChange={handleValueChange}
                    dataSources={dataSources}
                />;
            default:
                return <Typography color="error">Unknown filter type: {filter.type}</Typography>;
        }
    };

    return (
        <div className={"flex gap-2"}>
            <div className={cls("flex-1 flex flex-col gap-1 rounded-xl", defaultBorderMixin, className)}>
                <div className={"flex w-full items-center gap-1"}>
                    <Label className="font-medium flex-1">{filter.label}</Label>
                    <Typography variant={"caption"}
                                color={"secondary"}>{filter.key}</Typography>
                    {onFilterUpdate && <IconButton
                        size={"smallest"}
                        onClick={() => {
                            onSettingsOpen?.(true);
                            setFormOpen(true);
                        }}>
                        <SettingsIcon size={"smallest"}/>
                    </IconButton>}
                    {onFilterRemove && <IconButton
                        size={"smallest"}
                        onClick={() => {
                            onFilterRemove(filter);
                        }}>
                        <RemoveIcon size={"smallest"}/>
                    </IconButton>}
                </div>

                <div className="flex items-center gap-2">
                    {renderFilterInput()}
                </div>
            </div>

            {onFilterUpdate && <FilterConfigFormDialog
                formOpen={formOpen}
                setFormOpen={(open) => {
                    onSettingsOpen?.(open);
                    setFormOpen(open);
                }}
                filter={filter}
                onSave={(newFilter) => {
                    onFilterUpdate?.(newFilter);
                    onSettingsOpen?.(false);
                    setFormOpen(false);
                }}
                onCancel={() => {
                    onSettingsOpen?.(false);
                    setFormOpen(false);
                }}
            />}
        </div>

    );
});

interface BaseFilterInputProps {
    value: any;
    operator?: FilterOp;
    onValueChange: (value: any) => void;
    onOperatorChange?: (operator: FilterOp) => void;
    placeholder?: string;
    dataSources: DataSource[];
}

const OperatorSelect = React.memo(function OperatorSelect({
                                                              value,
                                                              onChange,
                                                              operators = ["==", "!=", ">", ">=", "<", "<="]
                                                          }: {
    value: FilterOp;
    onChange: (op: FilterOp) => void;
    operators?: FilterOp[];
}) {
    return (
        <Select
            size={"medium"}
            value={value}
            onChange={(e) => onChange(e.target.value as FilterOp)}
            className={cls("min-w-[60px] max-w-[70px] rounded-xl", defaultBorderMixin)}
        >
            {operators.map((op) => (
                <SelectItem key={op} value={op}>
                    {op}
                </SelectItem>
            ))}
        </Select>
    );
});

const TextExactFilterInput = React.memo(function TextExactFilterInput({
                                                                          value,
                                                                          onValueChange,
                                                                          placeholder
                                                                      }: Omit<BaseFilterInputProps, "operator" | "onOperatorChange">) {
    return (
        <TextField
            size={"medium"}
            value={value || ""}
            onChange={(e) => onValueChange(e.target.value)}
            placeholder={placeholder || "Enter exact text..."}
            className={cls("flex-grow rounded-xl", defaultBorderMixin)}
        />
    );
});

const TextSearchFilterInput = React.memo(function TextSearchFilterInput({
                                                                            value,
                                                                            onValueChange,
                                                                            placeholder
                                                                        }: Omit<BaseFilterInputProps, "operator" | "onOperatorChange">) {
    return (
        <TextField
            value={value || ""}
            size={"medium"}
            onChange={(e) => onValueChange(e.target.value)}
            placeholder={placeholder || "Search text..."}
            className={cls("flex-grow rounded-xl", defaultBorderMixin)}
        />
    );
});

const NumberFilterInput = React.memo(function NumberFilterInput({
                                                                    value,
                                                                    operator,
                                                                    onValueChange,
                                                                    onOperatorChange,
                                                                    placeholder
                                                                }: BaseFilterInputProps) {
    return (
        <>
            <OperatorSelect
                value={operator || "=="}
                onChange={onOperatorChange!}
            />
            <TextField
                type="number"
                size={"medium"}
                value={value || ""}
                onChange={(e) => onValueChange(e.target.value === "" ? null : Number(e.target.value))}
                placeholder={placeholder || "Enter a number..."}
                className={cls("flex-grow rounded-xl", defaultBorderMixin)}
            />
        </>
    );
});
const enumOptionsCache: Record<string, {
    data: { label: string; value: string | number | boolean }[],
    timestamp: number
}> = {};

// Create a cache key function
const createCacheKey = (sqlQuery: string, dataSources: DataSource[]): string => {
    return `${sqlQuery}_${dataSources.map(datasourceToString).join("_")}`;
};

const EnumFilterInput = React.memo(function EnumFilterInput({
                                                                value,
                                                                onValueChange,
                                                                sqlQuery,
                                                                options,
                                                                placeholder,
                                                                dataSources
                                                            }: Omit<BaseFilterInputProps, "operator" | "onOperatorChange"> & {
    sqlQuery?: string;
    options: { label: string; value: string | number | boolean }[];
}) {
    const {
        apiEndpoint,
        getAuthToken
    } = useDataki();

    const cacheKey = useMemo(() =>
            sqlQuery ? createCacheKey(sqlQuery, dataSources) : "",
        [sqlQuery, dataSources]
    );

    const cachedItem = cacheKey ? enumOptionsCache[cacheKey] : undefined;

    const [fetchedOptions, setFetchedOptions] = useState<{ label: string; value: string | number | boolean }[]>(
        cachedItem?.data || []
    );
    const [isLoading, setIsLoading] = useState<boolean>(!cachedItem);

    async function fetchValues() {
        if (!sqlQuery || !apiEndpoint || !cacheKey) return;

        // If we have cached data, don't show loading state
        if (!cachedItem) {
            setIsLoading(true);
        }

        const firebaseToken = await getAuthToken();

        makeSQLQuery({
            firebaseAccessToken: firebaseToken,
            apiEndpoint,
            sql: sqlQuery,
            dataSources
        })
            .then(rows => {
                if (rows && rows.length > 0) {
                    const newOptions = rows.map(row => {
                        return {
                            label: row.label || row.name || String(row.value),
                            value: row.value || row.id || row.label
                        };
                    });

                    // Update component state
                    setFetchedOptions(newOptions);

                    // Update the static cache
                    enumOptionsCache[cacheKey] = {
                        data: newOptions,
                        timestamp: Date.now()
                    };
                }
            })
            .catch(error => {
                console.error("Failed to fetch filter options:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        fetchValues();
    }, [sqlQuery, apiEndpoint]);

    // Memoize combined options
    const allOptions = useMemo(() => [...options, ...fetchedOptions], [options, fetchedOptions]);

    return (
        <MultiSelect
            value={value || []}
            onValueChange={onValueChange}
            size={"medium"}
            placeholder={isLoading ? "Loading options..." : placeholder || "Select..."}
            className={cls("flex-grow rounded-xl", defaultBorderMixin)}
            disabled={isLoading}
            includeSelectAll={false}
        >
            {allOptions.map((option) => (
                <MultiSelectItem key={String(option.value)} value={option.value.toString()}>
                    {option.label}
                </MultiSelectItem>
            ))}
        </MultiSelect>
    );
});

const BooleanFilterInput = React.memo(function BooleanFilterInput({
                                                                      value,
                                                                      onValueChange
                                                                  }: Omit<BaseFilterInputProps, "operator" | "onOperatorChange">) {
    return (
        <BooleanSwitchWithLabel
            size={"small"}
            value={Boolean(value)}
            onValueChange={onValueChange}
            className={cls("rounded-xl", defaultBorderMixin)}
        />
    );
});

const DateFilterInput = React.memo(function DateFilterInput({
                                                                value,
                                                                operator,
                                                                onValueChange,
                                                                onOperatorChange
                                                            }: BaseFilterInputProps) {
    return (
        <>
            <OperatorSelect
                value={operator || "=="}
                onChange={onOperatorChange!}
                operators={["==", "!=", ">", ">=", "<", "<="]}
            />
            <DateTimeField
                value={value}
                size={"small"}
                onChange={(date) => onValueChange(date)}
                className={cls("flex-grow rounded-xl", defaultBorderMixin)}
            />
        </>
    );
});

const DateRangeFilterInput = React.memo(function DateRangeFilterInput({
                                                                          value,
                                                                          onValueChange
                                                                      }: Omit<BaseFilterInputProps, "operator" | "onOperatorChange">) {
    return (
        <DatePickerWithRange
            dateRange={(Array.isArray(value) ? value : [null, null]) as [Date | null, Date | null]}
            setDateRange={(dateRange) => onValueChange(dateRange)}
            className={cls("flex-grow rounded-xl", defaultBorderMixin)}
        />
    );
});
